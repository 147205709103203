.aboutMeetTeamSection {
    padding-top: 64px;
    background: radial-gradient(50% 50% at 50% 50%, #0012e3 0%, #00046c 100%);
}

.aboutMeetTeamBG {
    background-image: url(../../../assets/images/banner-trangle-mission.svg);
    background-repeat: no-repeat;
    background-position: right bottom;
    padding-bottom: 94px;
}

.meetTeamArrowW {
    max-width: 62.289px;
    height: 62.289px;
    margin-bottom: 20px;
}

.titleMeetTeam {
    max-width: 491px;
    height: 23px;
    font-size: 50px;
    font-weight: 700;
    line-height: 48px;
}

.meetCardTeam {
    width: 380px;
    max-height: 510px;
    border-radius: 10px;
    margin: 0;
    padding: 0;
    background: #001358;
    margin-bottom: 24px;
    overflow: hidden;
    transition: max-height 500ms ease-in-out;
    &:hover {
        box-shadow: 0 0 8px #d3d3d3;
        cursor: pointer;
        max-height: 900px;
    }
}

.meetCardTeam .meetPhoto {
    width: 379.99px;
    height: 379.99px;
    left: 0px
 }

 .meetCardTeam .meetPhoto img {
    width: 100%;
    object-fit: cover;
}
.meetCardTeam .meetCnt {
    padding: 40px 40px 47px 40px;
    background: #001358
}
.meetCardTeam .meetCnt .teamName {
    font-size: 15px;
    font-weight: 700;
    line-height: 23px;
    margin-bottom: 10px;
}


.meetCardTeam .meetCnt .teamPosition {
    font-size: 11px;
    font-weight: 500;
    margin-bottom: 30px;
    height: 50px;
    color: #fff;
}

.flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .flex>div {
    flex: 1 0 50%;
  }
  
  .flex>div:first-child {
    flex: 0 1 100%;
  }

  .columns-3 {
    max-width: 100%;
    display:flex;
}
/* columns */
.columns-3 > * {
    width: calc(100% / 3);
}

.btnLoginCard {
    width: 180px;
    height: 48px;
    top: 480px;
    left: 88.5px;
    padding: 57px, 44px, 57px, 44px;
    border-radius: 4px;
    background-color: #FFFFFF;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #0A2647;
    text-align: center;
}

/* Media query for iPhone and mobile devices */
@media only screen and (max-width: 480px) {
    .btnLoginCard {
        width: 100%; /* Make the button full width */
        height: auto; /* Allow height to adjust based on content */
        padding: 15px; /* Adjust padding for smaller screens */
        font-size: 16px; /* Adjust font size for smaller screens */
    }
    .flex>div,
    .columns-3 > * {
        width: 100%; /* Make items full width on small screens */
    }
    .meetCardTeam .meetPhoto {
        width: 100%; /* Make the photo full width */
        height: auto; /* Allow height to adjust based on content */
    }

    .meetCardTeam .meetCnt {
        padding: 20px; /* Adjust padding for smaller screens */
    }

    .meetCardTeam .meetCnt .teamName {
        font-size: 12px; /* Adjust font size for smaller screens */
        line-height: 18px; /* Adjust line height for smaller screens */
    }

    .meetCardTeam .meetCnt .teamPosition {
        font-size: 10px; /* Adjust font size for smaller screens */
        margin-bottom: 20px; /* Adjust margin for smaller screens */
    }
    .meetCardTeam {
        width: calc(100% - 20px); /* Adjust width to fit smaller screens with some margin */
        max-height: none; /* Remove maximum height for smaller screens */
        border-radius: 0; /* Optionally remove border-radius for smaller screens */
        margin-bottom: 16px; /* Adjust margin for smaller screens */
        margin-left: 2%;
    }
    .aboutMeetTeamSection {
        padding-top: 40px; /* Adjust padding top for smaller screens */
    }

    .aboutMeetTeamBG {
        padding-bottom: 60px; /* Adjust padding bottom for smaller screens */
    }

    .meetTeamArrowW {
        max-width: 40px; /* Adjust max-width for smaller screens */
        height: 40px; /* Adjust height for smaller screens */
        margin-bottom: 10px; /* Adjust margin bottom for smaller screens */
        margin-left: 2%;
    }

    .titleMeetTeam {
        max-width: 300px; /* Adjust max-width for smaller screens */
        font-size: 30px; /* Adjust font size for smaller screens */
        line-height: 1.2; /* Adjust line height for smaller screens */
        margin-left: 2%;
    }
    .modelImgCnt {
        max-width: 100%; /* Adjust max-width for phones and iPhones */
    }
    
    .modelCntPara {
        max-width: 100%; /* Adjust max-width for phones */
        height: 100%;
    }
    .memberHeading {
        font-size: 18px; /* Decrease font size for better readability on phones */
        line-height: 22px; /* Adjust line height for better readability on phones */
    }
    .modal-content {
        padding: 30px;
        overflow: auto;
      }
      .btnLoginCard:hover {
        background-color: #fff;
        color: #0a2647;
      }
}


/* Media query for iPads */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .btnLoginCard {
        width: 100%; /* Make the button full width */
        max-width: 300px; /* Set maximum width for iPads */
        height: auto; /* Allow height to adjust based on content */
        padding: 15px; /* Adjust padding for iPads */
        font-size: 18px; /* Adjust font size for iPads */
    }
    .flex > div,
    .columns-3 > * {
        max-width: 100%; /* Make items full width on small screens */
    }
    .meetCardTeam .meetPhoto {
        width: 300px; /* Adjust width for iPads and tablets */
        height: 300px; /* Adjust height for iPads and tablets */
        object-fit: cover;
    }

    .meetCardTeam .meetCnt {
        padding: 30px; /* Adjust padding for iPads and tablets */
    }

    .meetCardTeam .meetCnt .teamName {
        font-size: 13px; /* Adjust font size for iPads and tablets */
        line-height: 20px; /* Adjust line height for iPads and tablets */
    }

    .meetCardTeam .meetCnt .teamPosition {
        font-size: 10px; /* Adjust font size for iPads and tablets */
        margin-bottom: 20px; /* Adjust margin for iPads and tablets */
    }
    .meetCardTeam {
        width: 90%; /* Adjust width for tablets */
        max-height:420px; /* Remove max-height for tablets */
    }
    .aboutMeetTeamSection {
        padding-top: 40px; /* Adjust padding top for tablets */
    }

    .aboutMeetTeamBG {
        padding-bottom: 60px; /* Adjust padding bottom for tablets */
    }

    .meetTeamArrowW {
        max-width: 40px; /* Adjust max-width for tablets */
        height: 40px; /* Adjust height for tablets */
        margin-bottom: 10px; /* Adjust margin bottom for tablets */
    }

    .titleMeetTeam {
        max-width: 350px; /* Adjust max-width for tablets */
        font-size: 40px; /* Adjust font size for tablets */
        line-height: 1.2; /* Adjust line height for tablets */
        margin-bottom: 20px;
    }
    .modelImgCnt {
        max-width: 100%; /* Adjust max-width for phones and iPhones */
    }
    
    .modelCntPara {
        width: 90%; /* Adjust width for tablets and iPads */
        max-width: 600px; /* Set a maximum width for tablets and iPads */
        height: auto; /* Allow height to adjust proportionally */
        gap: 30px; /* Adjust gap for better spacing */
    }
    .modal-content {
        padding: 30px;
        overflow: auto;
      }
      .btnLoginCard:hover {
        background-color: #fff;
        color: #0a2647;
      }
}

/* ONLY FOR RESOLUTION MEDIA QUERY */
@media only screen and (min-resolution: 121dpi) and (max-resolution: 200dpi) {
	/* Your styles for desktop devices with resolution more than 120% and up to 500% */
	.btnLoginCard {
        width: 100%; /* Make the button full width */
        max-width: 300px; /* Set maximum width for iPads */
        height: auto; /* Allow height to adjust based on content */
        padding: 15px; /* Adjust padding for iPads */
        font-size: 18px; /* Adjust font size for iPads */
    }
    .flex > div,
    .columns-3 > * {
        max-width: 100%; /* Make items full width on small screens */
    }
    .meetCardTeam .meetPhoto {
        width: 100%; /* Adjust width for iPads and tablets */
        height: 300px;
        object-fit: cover;
    }

    .meetCardTeam .meetCnt {
        padding: 30px; /* Adjust padding for iPads and tablets */
    }

    .meetCardTeam .meetCnt .teamName {
        font-size: 13px; /* Adjust font size for iPads and tablets */
        line-height: 20px; /* Adjust line height for iPads and tablets */
    }

    .meetCardTeam .meetCnt .teamPosition {
        font-size: 10px; /* Adjust font size for iPads and tablets */
        margin-bottom: 20px; /* Adjust margin for iPads and tablets */
    }
    .meetCardTeam {
        width: 90%; /* Adjust width for tablets */
        max-height:420px; /* Remove max-height for tablets */
    }
    .aboutMeetTeamSection {
        padding-top: 40px; /* Adjust padding top for tablets */
    }

    .aboutMeetTeamBG {
        padding-bottom: 60px; /* Adjust padding bottom for tablets */
    }

    .meetTeamArrowW {
        max-width: 40px; /* Adjust max-width for tablets */
        height: 40px; /* Adjust height for tablets */
        margin-bottom: 10px; /* Adjust margin bottom for tablets */
    }

    .titleMeetTeam {
        max-width: 350px; /* Adjust max-width for tablets */
        font-size: 40px; /* Adjust font size for tablets */
        line-height: 1.2; /* Adjust line height for tablets */
        margin-bottom: 20px;
    }
}

/* Styles for devices with screens smaller than 360 pixels in width */
@media only screen and (max-width: 359px) {
    .btnLoginCard {
        width: 100%; /* Make the button full width */
        height: auto; /* Allow height to adjust based on content */
        padding: 15px; /* Adjust padding for smaller screens */
        font-size: 16px; /* Adjust font size for smaller screens */
    }
    .flex>div,
    .columns-3 > * {
        width: 100%; /* Make items full width on small screens */
    }
    .meetCardTeam .meetPhoto {
        width: 100%; /* Make the photo full width */
        height: auto; /* Allow height to adjust based on content */
    }

    .meetCardTeam .meetCnt {
        padding: 20px; /* Adjust padding for smaller screens */
    }

    .meetCardTeam .meetCnt .teamName {
        font-size: 12px; /* Adjust font size for smaller screens */
        line-height: 18px; /* Adjust line height for smaller screens */
    }

    .meetCardTeam .meetCnt .teamPosition {
        font-size: 10px; /* Adjust font size for smaller screens */
        margin-bottom: 20px; /* Adjust margin for smaller screens */
    }
    .meetCardTeam {
        width: calc(100% - 20px); /* Adjust width to fit smaller screens with some margin */
        max-height: none; /* Remove maximum height for smaller screens */
        border-radius: 0; /* Optionally remove border-radius for smaller screens */
        margin-bottom: 16px; /* Adjust margin for smaller screens */
        margin-left: 2%;
    }
    .aboutMeetTeamSection {
        padding-top: 40px; /* Adjust padding top for smaller screens */
    }

    .aboutMeetTeamBG {
        padding-bottom: 60px; /* Adjust padding bottom for smaller screens */
    }

    .meetTeamArrowW {
        max-width: 40px; /* Adjust max-width for smaller screens */
        height: 40px; /* Adjust height for smaller screens */
        margin-bottom: 10px; /* Adjust margin bottom for smaller screens */
        margin-left: 2%;
    }

    .titleMeetTeam {
        max-width: 300px; /* Adjust max-width for smaller screens */
        font-size: 30px; /* Adjust font size for smaller screens */
        line-height: 1.2; /* Adjust line height for smaller screens */
        margin-left: 2%;
    }
    .modelImgCnt {
        max-width: 100%; /* Adjust max-width for phones and iPhones */
    }
    
    .modelCntPara {
        max-width: 100%; /* Adjust max-width for phones */
        height: 100%;
    }
    .memberHeading {
        font-size: 18px!important; /* Decrease font size for better readability on phones */
        line-height: 20px!important; /* Adjust line height for better readability on phones */
    }
    .modal-content {
        padding: 30px;
        overflow: auto;
      }
      .btnLoginCard:hover {
        background-color: #fff;
        color: #0a2647;
      }
}

.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0px;
    border-bottom: 1px solid white;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}
.modelCard{
    padding: 0px;
}

.card_header_dialog {
    border: 1px solid white!important;
}
.modelImgCnt {
    width: 100%; /* Set width to 100% to make it responsive */
    max-width: 540.76px; /* Set a maximum width */
    height: auto; /* Allow height to adjust proportionally */
}


.modelCntPara {
    width: 596.79px;
    height: 700px;
    gap: 50px
}
.modelContentImg{
    width: 85%!important;
    object-fit: cover;
    object-position: center;
    aspect-ratio: 0!important;
}
.memberHeading{
    font-family: Roboto;
    font-size: 20px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #001358;
    
}
.subTeamName {
    font-family: Roboto;
    font-size: 20px;
    font-size: 17px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 10px;
    color: #212121;
}
.memberParaDesc{
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #212121;
    margin-top: 10px;
}