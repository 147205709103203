@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400;500;700&display=swap");

html,
body {
  height: 100%;
}
body {
  margin: 0px;
  padding: 0px;
  height: 100%;
  font-size: 18px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  
}


/* Media query for iPad Air and iPad Mini */
@media only screen and (max-device-width: 1024px) {
  /* Adjustments for iPad Air and iPad Mini */
  html, body {
    font-size: 16px; /* Adjust font size for smaller screens */
   
  }
}


img {
  max-width: 100%;
}
a,
a:hover {
  text-decoration: none;
}

/* this css for ios on focus issue*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input,
  .input-text {
    font-size: 16px;
  }

  .input-text:focus {
    font-size: 16px;
  }
}





/* this css for ios on focus issue*/
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: 0px;
}
::-webkit-input-placeholder {
  color: inherit;
}
::-moz-placeholder {
  color: inherit;
}
:-ms-input-placeholder {
  color: inherit;
}
:-moz-placeholder {
  color: inherit;
}

h1,
h2,
h3,
h4 {
  margin: 0px;
  padding: 0px;
  padding: 0px;
  font-size: 20px;
  font-weight: normal;
  
}
.cp {
  cursor: pointer;
}
.height941 {
  height: 941px;
}
:root {
  /* fonts */
  --text-style-body-body-l: Roboto;
  --font-inter: Inter;
  --socialhumanequity-com-1440x810-default-font-awesome-5-free-solid-105: "Font Awesome 5 Free";
  --sub-menu: "Pixel Operator HB 8";
  --font-times-new-roman: "Times New Roman";
  --font-dm-serif-display: "DM Serif Display";
  --font-gilroy: Gilroy;
  --validation-text-h1-regular: Poppins;

  /* font sizes */
  --font-size-17xl: 2.25rem;
  --text-style-body-body-l-size: 1rem;
  --button-text-size: 1.5rem;
  --text-style-title-title-l-size: 1.375rem;
  --font-size-xl: 1.25rem;
  --font-size-lg: 1.125rem;
  --font-size-11xl: 1.875rem;
  --font-size-31xl: 3.125rem;
  --socialhumanequity-com-1440x810-default-font-awesome-5-free-solid-105-size: 0.656rem;
  --font-size-5xs-6: 0.475rem;
  --font-size-51xl: 4.375rem;
  --font-size-xs: 0.75rem;
  --font-size-29xl: 3rem;
  --font-size-mid-5: 1.094rem;
  --font-size-16xl: 2.188rem;
  --validation-text-h1-regular-size: 0.625rem;
  --font-size-13xl: 2rem;
  --body-text-regular-size: 1.063rem;
  --font-size-23xl: 2.625rem;
  --font-size-37xl: 3.5rem;
  --font-size-sm: 0.875rem;
  --font-size-lgi: 1.188rem;

  /* Colors */
  --color-styles-primary-color-primary-5: #0a2647;
  --color-gray-100: #161d3f;
  --color-gray-200: #161c2d;
  --www-obforum-com-2024-1440x810-default-cod-gray: #161616;
  --color-gray-300: rgba(141, 141, 141, 0.75);
  --www-obforum-com-2024-1440x810-default-nero-02: rgba(255, 255, 255, 0);
  --color-gray-400: rgba(0, 0, 0, 0.2);
  --www-obforum-com-about-us-1440x810-default-nero: #fff;
  --color-gainsboro-100: #e6e6e6;
  --color-gainsboro-200: #d9d9d9;
  --www-obforum-com-1440x810-default-gold: #f4d000;
  --www-obforum-com-about-us-1440x810-default-stratos: #001358;
  --color-styles-primary-color-primary-3: #0014ff;
  --color-styles-neutral-color-neutral-5: #000;
  --color-styles-secondary-color-secondary-4: #0a74dc;
  --red1: #ef4040;
  --color-mediumblue: #473bf0;
  --customer-portal-primary: #2248ac;
  --color-darkslateblue-100: rgba(26, 24, 72, 0.35);
  --unsuccesfull-incorrect: #ff2525;
  --color-royalblue: #476ed4;

  /* Gaps */
  --gap-0: 0rem;
  --gap-8xl: 1.688rem;
  --gap-base: 1rem;
  --gap-11xl: 1.875rem;
  --gap-xl: 1.25rem;
  --gap-8xs: 0.313rem;
  --gap-7xs: 0.375rem;
  --gap-3xs: 0.625rem;
  --gap-12xl: 1.938rem;
  --gap-10xs: 0.188rem;
  --gap-27xl: 2.875rem;
  --gap-mini: 0.938rem;
  --gap-160xl: 11.188rem;
  --gap-57xl: 4.75rem;
  --gap-9xs: 0.25rem;

  /* Paddings */
  --padding-3xs: 0.625rem;
  --padding-38xl: 3.563rem;
  --padding-25xl: 2.75rem;
  --padding-mid: 1.063rem;
  --padding-139xl: 9.875rem;
  --padding-275xl: 18.375rem;
  --padding-15xl-8: 2.175rem;
  --padding-16xl-2: 2.2rem;
  --padding-35xl-3: 3.394rem;
  --padding-35xl-7: 3.419rem;
  --padding-11xl: 1.875rem;
  --padding-31xl-3: 3.144rem;
  --padding-31xl-7: 3.169rem;
  --padding-40xl-8: 3.738rem;
  --padding-41xl-2: 3.763rem;
  --padding-20xl-8: 2.488rem;
  --padding-21xl-2: 2.513rem;
  --padding-8xl-8: 1.738rem;
  --padding-9xl-2: 1.763rem;
  --padding-8xl: 1.688rem;
  --padding-xl: 1.25rem;
  --padding-5xl: 1.5rem;
  --padding-12xs-5: 0.031rem;
  --padding-6xs-3: 0.394rem;
  --padding-7xs: 0.375rem;
  --padding-6xs: 0.438rem;
  --padding-4xl: 1.438rem;
  --padding-4xl-5: 1.469rem;
  --padding-3xs-7: 0.606rem;
  --padding-4xl-2: 1.45rem;
  --padding-6xs-1: 0.381rem;
  --padding-11xs-1: 0.069rem;
  --padding-154xl-5: 10.844rem;
  --padding-28xl: 2.938rem;
  --padding-21xl: 2.5rem;
  --padding-16xl: 2.188rem;
  --padding-smi: 0.813rem;
  --padding-852xl: 54.438rem;
  --padding-11xs: 0.125rem;
  --padding-41xl: 3.75rem;
  --padding-12xs-7: 0.044rem;
  --padding-64xl-2: 5.2rem;
  --padding-64xl-5: 5.219rem;
  --padding-8xs: 0.313rem;
  --padding-116xl-7: 8.481rem;
  --padding-31xl: 3.125rem;
  --padding-17xl-7: 2.294rem;
  --padding-39xl: 3.625rem;
  --padding-xs: 0.75rem;
  --padding-15xl-5: 2.156rem;
  --padding-lg: 1.125rem;
  --padding-base: 1rem;
  --padding-52xl: 4.438rem;
  --padding-2xl: 1.313rem;
  --padding-9xs: 0.25rem;
  --padding-12xs: 0.063rem;
  --padding-9xl: 1.75rem;
  --padding-10xl: 1.813rem;
  --padding-12xl: 1.938rem;
  --padding-7xl: 1.625rem;
  --padding-249xl: 16.75rem;
  --padding-29xl: 3rem;
  --padding-10xs: 0.188rem;
  --padding-6xl: 1.563rem;

  /* Border radiuses */
  --br-9xs: 4px;
  --br-base: 16px;
  --br-11xl: 30px;
  --br-6xs-4: 6.4px;
  --br-5xs: 8px;
  --br-8xs: 5px;
}