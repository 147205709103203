.homeENFASection {
  background: radial-gradient(50% 50% at 50% 50%, #0014ff 0%, #0007b9 100%);
}
.homeENFASection .leftSide {
  width: 50%;
  flex-shrink: 0;
}
.homeENFASection .rightSide {
  width: 50%;
  flex-shrink: 0;
  padding-top: 94px;
}
.homeENFASection .rightSide span {
  display: block;
  font-size: 30px;
  font-weight: 700;
  line-height: 37px;
  margin-bottom: 37px;
  width: 420px;
}
.homeENFASection .rightSide p {
  margin: 0;
  padding: 0;
  display: block;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  margin-bottom: 27px;
  width: 560px;
}
@media (min-width: 768px) {
  .homeENFASection {
      flex-direction: row;
  }

  .homeENFASection .leftSide,
  .homeENFASection .rightSide {
      width: 50%;
  }

  .homeENFASection .rightSide span {
      font-size: 30px;
      line-height: 37px;
      margin-bottom: 37px;
      width: 420px;
  }

  .homeENFASection .rightSide p {
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 27px;
      width: 560px;
  }
}

@media (max-width: 767px) {
  .homeENFASection .rightSide span,
  .homeENFASection .rightSide p {
      width: 100%; 
  }
  .homeENFASection .leftSide,
  .homeENFASection .rightSide {
      width: 100%;
  }
  .homeENFASection .rightSide {
  padding: 40px;
}
}