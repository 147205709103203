.registrationBannerSection {
  margin: 0;
  padding: 0;
}

.registrationBannerSectionBG {
  height: 735.876px;
  background: url(../../../assets/images/Registration/Background.png) no-repeat
    center top;
  background-size: cover;
}

.registrationBannerSection .bannerHeadingReg {
  font-size: 4.375rem;
  font-weight: 700;
  line-height: 4.6875rem;
  color: #fff;
  width: 43.45044rem;
  margin-top: 1.25rem;
}
@media (max-width: 768px) {
  .registrationBannerSection .bannerHeadingReg {
    font-size: 3.5rem;
    line-height: 4rem;
    width: auto;
  }
}

@media (max-width: 480px) {
  .registrationBannerSection .bannerHeadingReg {
    font-size: 2.5rem;
    line-height: 3rem;
    width: auto;
    margin-top: 0.625rem;
  }
}
