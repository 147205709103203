.summitSpeaker23 {
    padding-top: 102px;
    padding-bottom: 42px;
    background: radial-gradient(50% 50% at 50% 50%, #0012e3 0%, #00046c 100%);
    background-image: url(../../../assets//images//summit/SummerSpeakerBG.png);
    background-repeat: no-repeat;
    background-position: right top;
    background-size: cover;

}
.speakersCards23 p.desofspeaker {
    text-align: center;
    font-size: 12px;
    line-height: 14px;
}
.summitSpeaker23BG {
    margin-bottom: -30px;
}

.ourSpearkText23 {
    margin-bottom: 91px;
}

.speakersCards23 {
    margin: 0;
    padding: 0;
    margin-bottom:50px;
}

.speakersCards23 .img {
    width: 100%;
    height: 379px;
    margin-bottom: 10px;
    
}

.speakersCards23 .img img {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 5;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.speakersCards23 h3 {
    margin: 0;
    padding: 0;
    font-family: Roboto;
    font-size: 17px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    
    color: #fff;  
}

.speakersCards23 p {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    margin-bottom: 20px;
}

.speakersCards23 .anchReadmore23 {
    margin: 0;
    padding: 8px 0px 13px 0px;
    width: 145px;
    height: 45px;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    color: #fff;
    border-radius: 4px;
    border: 1px solid #fff;
    margin-bottom: 20px;
}

.speakersCards23 .anchReadmore23:hover {
    background-color: #0014ff;
}

.trangleSpear23 {
    bottom: 0;
    z-index: 1;
    width: 100%;
}
.nameofspeaker{
    font-family: Roboto;
    font-size: 17px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;    
}

/* Media query for Android phones */
@media only screen and (min-device-width: 360px) and (max-device-width: 740px) {
    .speakersCards23 .img {
        width: 100%;
        height: 198px;
    }
    .speakersCards23 h3 {
        margin: 0;
        padding: 0;
        font-size: 13px;
        font-weight: 700;
        color: #fff;
        /* margin-bottom: 20px; */
    }
    .speakersCards23 {
        margin: 0;
        padding: 0;
        margin-bottom:20px;
    }
}

/* Media query for iPhones */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) {
    .speakersCards23 .img {
        width: 100%;
        height: 198px;
    }
    .speakersCards23 h3 {
        margin: 0;
        padding: 0;
        font-size: 13px;
        font-weight: 700;
        color: #fff;
        /* margin-bottom: 20px; */
    }
    .speakersCards23 {
        margin: 0;
        padding: 0;
        margin-bottom:20px;
    }
}

/* Responsive styles for tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {}



/* Styles for devices with screens smaller than 360 pixels in width */
@media only screen and (max-width: 359px) {
    .speakersCards23 .img {
        width: 100%;
        height: 150px;
    }
    .speakersCards23 h3 {
        margin: 0;
        padding: 0;
        font-size: 13px;
        font-weight: 700;
        color: #fff;
        /* margin-bottom: 20px; */
    }
    .speakersCards23 {
        margin: 0;
        padding: 0;
        margin-bottom:20px;
    }
}