
.summitAboutbannerSection {
  margin: 0;
  padding: 0;
}

.summitAboutbannerSectionBG {
  height: 735.876px;
  background: url(../../../assets/images/BusinessSummitBG.png) no-repeat left top;
  background-size: cover;
}

.summitAboutbannerSection .bannerHeadingsec {
  font-size: 4.375rem;
  font-weight: 700;
  line-height: 2.625rem; 
  color: #fff;
  width: 100%; 
  margin-top: 1.25rem;

}

.SummitAboutSecDates {
  font-size: 1.5rem; 
  font-weight: 500;
  margin-left: 1.375rem;
  margin-right: 1.375rem;
  margin-top: 1.25rem; 
}

.SummitAboutSecDates span {
  margin-left: 1.375rem;
  margin-right: 1.375rem;
}

@media screen and (max-width: 767px) {
  .summitAboutbannerSection .bannerHeadingsec {
    font-size: 2rem;
    line-height: 0.125rem; 
  }
  .SummitAboutSecDates {
    font-size: 1.125rem;
    margin-left: 0;
    margin-right: 0;
    text-align: center;
  }
  .SummitAboutSecDates span {
   margin: 0;
  }
}
