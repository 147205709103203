.summitAbout {
  background-color: #161c2d;
  padding-top: 0px
}

.summitAboutBG {
  background-image: url(../../../assets/images/summit/summitAboutBG.png);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
}

.summitAboutBG .leftImg {
  width: 40%;
}
.praEmpowerDivSummit23 p.para1 {
  margin: 0;
}
.summitAboutBG .rightContent {
  width: 60%;
}
.summitAboutBG .rightContent .textYear {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 10px;
  margin-top: 100px;
}

.summitAboutBG .rightContent h4 {
  font-size: 48px;
  color: #fff;
  font-weight: 700;
  line-height: 75px;
  margin-bottom: 10px;
}

.summitAboutBG .rightContent p {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  margin: 0;
  margin-bottom: 26px;
  width: 85%;
}

.summitAboutBG .rightContent .textBold {
  font-size: 18px;
  font-weight: 900;
  line-height: 27px;
  letter-spacing: 0.5px;
  padding-bottom: 90px;
}

@media screen and (max-width: 768px) {

  .summitAboutBG .leftImg {
    width: 100% !important;
  }

  .summitAboutBG .rightContent {
    width: 100%;
  }

  .summitAboutBG .rightContent p {
    width: 100%;
  }
  .summitAboutBG .rightContent .textYear {
    margin-top: 40px;
  }
  .summitAboutBG .rightContent h4 {
    line-height: 50px;
    font-size: 45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .summitAboutBG .leftImg {
    width: 100% ;
  }

  .summitAboutBG .rightContent {
    width: 100%;
  }

  .summitAboutBG .rightContent p {
    width: 100%;
  }
}