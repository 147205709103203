body {
	margin: 0px;
	padding: 0px;
	height: 100%;
	font-size: 18px;
	color: #fff;
	font-family: "Roboto", sans-serif;
	font-weight: 400;
	font-style: normal;
}

/* about us css */
.aboutSection {
	margin: 0;
	padding: 0;
}