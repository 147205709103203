/* Home more info css slider   */
.homeMoreInfoSection {
  background-color: #141414;
  padding-top: 118px;
  background-image: url(../../../assets/images/RectangleBGIMG.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 50px;
}
@media screen and (min-width: 1024px) {
.billionBlock {
  width: 809px;
}
}
.billionBlock span {
  font-size: 36px;
  font-weight: 700;
  line-height: 52px;
  margin-bottom: 50px;
}

.billionBlock p {
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 0px;
  padding-bottom: 40px;
}

.moreInfoCard {
  margin: 0;
  padding: 0;
  text-align: center;
  cursor: default;
}

.moreInfoCard .img {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 180px;
  margin-bottom: 16px;
}

.moreInfoCard .img img {
  height: 100%;
}

.moreInfoCard h4 {
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -1px;
  color: #fff;
  margin-bottom: 12px;
 
}

.moreInfoCard span {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -1px;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .billionBlock {
    width: 100%;
  }
}
/* Media Query for Tablet */
@media screen and (max-width: 1024px) {
  .billionBlock span {
    font-size: 28px; 
    line-height: 42px; 
    margin-bottom: 40px; 
  }
  .billionBlock p {
    font-size: 18px; 
    line-height: 24px; 
    padding-bottom: 60px; 
  }
}

/* Media Query for Mobile */
@media screen and (max-width: 767px) {
  .billionBlock {
    width: 100%;
  }
  .billionBlock span {
    font-size: 24px; 
    line-height: 36px; 
    margin-bottom: 30px; 
  }
  .billionBlock p {
    font-size: 16px; 
    line-height: 22px; 
    padding-bottom: 50px; 
  }
}