.ticketPricesSection {
  background-color: #161c2d;
  background-image: url(../../../assets/images/summit/summitTicketSec.png);
  background-repeat: no-repeat;
  background-position: top right;
  padding-top: 50px;
  padding-bottom: 40px;
}

.textTicket {
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  margin-bottom: 30px;
}

.textPrices {
  font-size: 30px;
  font-weight: 700;
  line-height: 37px;
  margin-bottom: 20px;
}

.praApprox {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  margin-bottom: 40px;
}

/* ticket */
.ticketCards {
  margin: 0px 0px 30px 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 3px solid #f4d000;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.ticketCards .earlyBird {
  background: #f4d000;
  width: 240px;
  height: 40px;
  transform: rotate(45deg);
  padding: 6px 76.293px 12.608px 76.707px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  top: 26px;
  right: -80px;
}

.ticketContent {
  margin: 0;
  padding: 40px 35px 26px 35px;
}

.ticketContent .cntTop {
  margin: 0;
  padding: 0;
}

.cntTop .silverPass {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 2px;
  margin-bottom: 30px;
}

.cntTop .silverPass .silverPassSubtype {
  font-size: 0.625rem;
  padding: 5px;
}

.cntTop .silverPass sub {
  font-size: 10px;
}

.cntTop .smallPrice {
  font-size: 16px;
  text-decoration: line-through;
  font-weight: 500;
  line-height: 19px;
  margin-bottom: 16px;
}

.cntTop .bigPrice {
  font-size: 35px;
  font-weight: 700;
  line-height: 41px;
}

.ulTicket {
  margin: 0;
  padding: 0;
  list-style: none;
  height: fit-content;
}

.ulTicket li {
  margin: 0px 0px 2px 0px;
  padding: 0px 0px 0px 28px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  background: url(../../../assets/images/ticket-w.svg) no-repeat left top;
}

.ulTicket li b {
  font-weight: 700;
}

.btnRegisterTicket {
  margin: 10px 0px 0px 0px;
  padding: 9px 0px 0px 0px;
  width: 175px;
  height: 45px;
  border-radius: 4px;
  background: #fff;
  font-size: 18px;
  font-weight: 400;
  color: #0a2647;
  border: 0;
  cursor: pointer;
  ;
}

.btnRegisterTicket:hover {
  background: #010998;
  color: #fff;
}

.ticketCards:hover {
  background-color: #f4d000;
  color: #161616;
}

.ticketCards:hover .earlyBird {
  background-color: #fff;
  color: #7e7e7e;
}

.ticketCards:hover .ulTicket li {
  background: url(../../../assets/images/ticket-black.svg) no-repeat left top;
}

.ticketCards:hover .btnRegisterTicket {
  background: #010998;
  color: #fff;
}

.btnRegisterTicket.active {
  background: #0014ff;
  color: #fff;
}

.ticketCards.active {
  background-color: #f4d000;
  color: #161616;
  border: 0px;
}

.ticketCards.active .earlyBird {
  background-color: #fff;
  color: #7e7e7e;
}

.ticketCards.active .ulTicket li {
  background: url(../../../assets/images/ticket-black.svg) no-repeat left top;
}

.ticketCards.active .btnRegisterTicket {
  background: #010998;
  color: #fff;
}

/* ticket */
.additionalOffer,
.SummarySec {
  border-radius: 4px;
  border: 4px solid #f4d000;
  background: #f4d000;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.innerBorder {
  padding: 40px 35px;
  border-radius: 4px;
  border: 2px solid #ffffff;
  background: #131725;
}

.textadditinalOffers {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 2px;
  margin-bottom: 40px;
}

.ulTicket.ulOffers {
  margin-bottom: 74px;
}

.quantity {
  display: flex;
  align-items: center;
}

.quantity button {
  background-color: transparent;
  cursor: pointer;
  border: 1px solid #155ca3;
  height: 2em;
  width: 3em;
  padding-top: 15px;
  padding-bottom: 15px;
  max-width: 30px !important;
  max-height: 30px !important;
  margin: 0 3px;
  color: white;
  font-size: 1.3em;
  display: flex;
  height: 100%;
  max-height: 30px;
  justify-content: center;
  align-items: center;
}

.quantity input {
  width: 3rem;
  text-align: center;
  margin: 0 5px;
  border: none;
  color: white;
  background: transparent;
}

.summaryContainer {
  width: 880px;
  padding-top: 50px;
  padding-bottom: 50px;
}

/* Styles for the popup overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease-in-out;
}

.popup {
  background-color: #fff;
  padding: 5px;
  border-radius: 8px;
  position: relative;
  margin-top: 100px;
}

.registrationForm .close-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  z-index: 1;
  align-items: center;
  justify-content: center;
  height: 26px;
  width: 26px;
  padding: 0;
  border: none;
  border-radius: 5px;
  background: none;
  color: #fff;
}

.close-popup::before {
  content: "×";
  font-size: larger;
}

.popup-content {
  max-height: 80vh;
  overflow-y: auto;
  padding-right: 17px;
  scrollbar-width: thin;
  scrollbar-color: transparent #555;
}

.eurCurrency {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  /* 118.75% */
  padding-left: 10px;
}

.price {
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .summaryContainer {
    width: 100%;
  }

  .popup {
    margin: 10px;
  }

  .cntTop .silverPass {
    margin-bottom: 20px;
  }

  .cntTop .price {
    margin-bottom: 4px;
  }

  .btnRegisterTicket:hover {
    background: #fff;
    color: #0a2647;
  }

  .ticketCards:hover {
    background: rgba(0, 0, 0, 0.2);
    color: #fff;
  }
  
  .ticketCards:hover .ulTicket li {
    background: url(../../../assets/images/ticket-w.svg) no-repeat left top;
  }
  
  .ticketCards:hover .btnRegisterTicket {
    background: #fff;
    color: #0a2647;
  }
  .ticketCards:hover .earlyBird {
    background: #f4d000;
    color: #fff;
  }
}

@media only screen and (max-width: 1024px) {
  .ticketContent {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .ticketContent {
    display: block;
  }
}