.homeFundingImpactSection {
  margin: 0;
  padding: 0;
}

.homeFundingImpactSectionBG {
  height: 735.876px;
  background: url(../../../assets/images/BusinessSummitBG.png) no-repeat left top;
  background-size: cover;
}

.homeFundingImpactSection .bannerHeading {
  text-align: center;
  font-size: 4.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: 4.6875rem;
  margin-top: 1.25rem;
}
.btnSeeEvent {
  margin: 40px 0px 40px 0px;
  padding: 8px 0px 0px 0px;
  width: 180px;
  height: 48px;
  border-radius: 4px;
  background: #fff;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #0a2647;
  border: 0;
}

.btnSeeEvent:hover {
  background: #0014ff;
  color: #fff;
}

.FundingImpactDates {
  font-size: 1.5rem;
  font-weight: 500;
  margin-left: 1.375rem;
  margin-right: 1.375rem;
  margin-top: 1.25rem;
}

.FundingImpactDates span {
  margin-left: 1.375rem;
  margin-right: 1.375rem;
}
@media only screen and (max-width: 480px) {
  .btnSeeEvent {
    font-size: 16px;
    padding: 10px 0px 0px 0px;
  }
  .btnSeeEvent:hover {
    background: #fff;
    color: #0a2647;
  }
}
@media screen and (max-width: 767px) {
  .homeFundingImpactSection .bannerHeading {
    font-size: 2rem;
    line-height: 2.125rem;
  }

  .btnSeeEvent {
    font-size: 18px;
    padding: 10px 0px 0px 0px;
  }

  .FundingImpactDates {
    font-size: 1.125rem;
    margin-left: 0;
  }
  .btnSeeEvent:hover {
    background: #fff;
    color: #0a2647;
  }
}
