.AboutCity-section {
  background-image: url(../../../assets/images/Registration/RegistrationFormBG.png);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
  background-color: #161c2d;
  padding-top: 50px;
}

.AboutCitySectionHeading {
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.6875rem;
  margin-bottom: 34px;
  text-transform: uppercase;
}

.aboutCity {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  text-transform: uppercase;
}

.aboutCityCard {
  border: 3px solid #fff;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 16px 14px 16px 14px;
  margin-top: 20px;
}
.aboutCityCard .content.hotel-info .travel-info {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
}
.aboutCityCard .content.hotel-info .to-and-from {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
}
span.to-address, .to-price, .to-link, .to-phone {
  font-size: 13px;
  font-family: Roboto;
  line-height: 16px;
  font-weight: 400;
}
.aboutCityCard.hotelCard {
  height: 100%;
}
.to-link a {
  color: #fff;
  text-decoration: none;
  word-wrap: break-word;
}
span.to-link {
  width: 100%;
}
.aboutCityCard .image {
  width: 100%;
  height: 198px;
}

.aboutCityCard .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.aboutCityCard .content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.aboutCityCard .content .travel-info {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  margin-top: 13px;
}

.aboutCityCard .content .to-and-from {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
}

.aboutCityCard .content .read-more {
  display: inline-flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--www_obforum_com_2024_1440x810_default-Nero, #fff);
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.5px;

}

.thanks-to-stockholm {
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  width: 1008.285px;
}

.Getting-around-StockholmCard {
  display: inline-flex;
  height: 486px;
  align-items: center;
  flex-shrink: 0;
  margin: 45px 0;
}

.Getting-around-StockholmCard .Getting-around-image {
  border: 3px solid #fff;
}

.trevelInfoSectionHeading {
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  text-transform: uppercase;
  margin: 3.125rem 0 0 0;
}

.aboutcityP {

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  width: 482.427px;
}

.modal-content {
  padding: 30px;
}

.modal-content img {
  width: 100%;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 16/9;
}

.modal-content .title-small {
  color: var(--www_obforum_com_about-us_1440x810_default-Stratos, #001358);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}

.modal-content .title-large {
  color: var(--www_obforum_com_about-us_1440x810_default-Stratos, #001358);
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px;
}

.modal-content .body {
  font-family: Roboto;
  font-size: 22px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #212121;

}

@media (max-width: 768px) {
  .AboutCitySectionHeading {
    font-size: 2rem;
    line-height: 2rem;
    width: 100%;
  }

  .Getting-around-StockholmCard {
    height: 100%;
  }

  .thanks-to-stockholm {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    width: 100%;
  }

  .aboutcityP {
    width: 100%;
  }
}

.Getting-around-image {
  position: relative;
}

.label {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  padding: 5px;
}

.labelPublic {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
}

.paragraphAboutcity {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  /* 150% */
}

.Getting-around-image img {
  width: 100%;
}

.rightImgAboutCity {
  width: 100%;

}

@media only screen and (max-width: 481px) {
  .labelPublic {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
  }

  .paragraphAboutcity {
    font-size: 5px;
    font-style: normal;
    font-weight: 200;
    line-height: 5px;
    /* 150% */
  }
}

@media screen and (max-width: 1024px) {
  .aboutcityP {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .thanks-to-stockholm {
    width: 100%;
  }
  .Getting-around-StockholmCard{
    height: auto;
  }
}