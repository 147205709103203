/* Slideshow */
.slideshow {
    margin: 0 auto;
    margin: 20px;
    overflow: hidden; /* Ensure slides don't overflow */
}

.slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
    font-size: 0; /* Remove any potential gaps between slides */
}

.slide {
    display: inline-block;
    height: 200px; /* Adjust height as needed */
    width: 100%; /* Make slides take full width */
    max-width: 300px; /* Set maximum width for each slide */
    margin: 10px auto; /* Center slides horizontally */
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

/* Buttons */
.slideshowDots {
    text-align: center;
    margin-bottom: 20px;
}

.slideshowDot {
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    cursor: pointer;
    margin: 15px 7px 0px;
    background-color: #2250C5;
    border: 1px solid white;
}

.slideshowDot.active {
    background-color: #fff;
}

    .image-container {
        overflow: hidden; 
      }
      
      .image-container img {
        transition: transform 0.3s ease; 
        width: 100%; 
        height: 185px; 
      }
      
      .image-container img:hover {
        transform: scale(1.1); 
      }
      .summitSpeakerSlider.modal-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
      }
      
      .summitSpeakerSlider .modal-content {
        position: relative;
        background: #fff;
        border-radius: 8px;
        padding: 10px;
        max-width: 60%;
        max-height: 50%;
        width: 700px; 
        height: auto; 
        overflow: hidden;
        text-align: center;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
      }
      
      .summitSpeakerSlider .modal-image {
        width: 100%;
        height: auto;
        border-radius: 8px;
        max-height: 90%; 
        margin-top: 20px;
      }
      
      .summitSpeakerSlider .close-button {
        top: 0;
        position: absolute;
        right: 5px;
        font-size: 20px;
        background: none;
        border: none;
        color: #333;
        cursor: pointer;
      }  
/* Media Query for screens smaller than 768px */
@media screen and (max-width: 767px) {
    .slide {
        height: 200px; /* Adjust height for smaller screens */
    }
}

.summitbannerContent{
    font-size: 1.5rem;
    font-weight: 700;
}


/* iPhone Styles */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px) 
  and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles for iPhones */
  #mobileView{
    visibility: visible;
  }
  #deskTopView{
   display: none;
  }
}

/* Android Styles */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
  /* Styles for Android phones */
  #mobileView{
    visibility: visible;
  }
  #deskTopView{
   display: none;
  }
}

/* Styles for screens wider than 768px (typical tablet or desktop screens) */
@media screen and (min-width: 768px) {
    #mobileView{
        display: none!important;
      }
      #deskTopView{
       visibility: visible;
      }
}