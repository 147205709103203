.Organisation-section {
  background-image: url(../../../assets/images/Registration/RegistrationFormBG.png);
  background-color: #161c2d;
  padding-top: 100px;
  background-position: 0px;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 100px;
}
.OrganisationSectionHeading {
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.6875rem;
  margin-bottom: 42px;
  width: 37.27856rem;
}
p.heading-bg {
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 10px;
}
.btnRegister.btn-register {
  margin: 40px 0px 40px 0px;
  width: 85%;
  height: 40px;
  border-radius: 4px;
  background: #fff;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #0a2647;
  align-self: baseline;
  display: flex;
  justify-content: left;
  padding: 10px;
  text-align: center;
}
.membership {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  text-transform: uppercase;
}
.ulList {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.25rem;
  padding-right: 20px;
  margin-bottom: 40px;
}
.listItem{
  margin: 0px 0px 20px 0px;
  
}


@media (max-width: 768px) {
  .OrganisationSectionHeading {
    font-size: 2rem;
    line-height: 2rem;
    width: 100%;
  }
}

@media (min-width: 1200px){
  .right-img {
    transition: transform .5s ease;
  }
  
  .right-img:hover {
    transform: scale(1.1);
  }
}
