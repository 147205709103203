@media screen and (max-width: 767px) {
    .slide {
        height: 200px; /* Adjust height for smaller screens */
    }
}
.slideVideo{
  display: inline-block;
  height: 200px; /* Adjust height as needed */
  width: 100%; /* Make slides take full width */
  max-width: 49%; /* Set maximum width for each slide */
  margin: 10px auto; /* Center slides horizontally */
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.slideVideoFull{
  display: inline-block;
  height: 200px; /* Adjust height as needed */
  width: 100%; /* Make slides take full width */
  max-width: 100%; /* Set maximum width for each slide */
  margin: 10px auto; /* Center slides horizontally */
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.summitbannerContent{
    font-size: 1.5rem;
    font-weight: 700;
}

.slideCard{
    display: inline-block;
    width: 100%; 
    margin: 20px auto; 
    border-radius: 10px;
    padding: 10px;
}
.slideshow {
    margin: 0 auto;
    margin: 20px;
    overflow: hidden; /* Ensure slides don't overflow */
    
}

.slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
    font-size: 0; /* Remove any potential gaps between slides */
    margin-bottom: 20px;
}

.slideshowDots {
    text-align: center;
    margin-bottom: 20px;
}

.slideshowDotPartner {
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    cursor: pointer;
    margin: 15px 7px 0px;
    background-color: #161C2D;
    border: 1px solid white;
}

.slideshowDotPartner.active {
    background-color: #fff;
}

.videoSource{
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
}
.video{
    /* height:520px; */
    background-color: black;
}
.cardTestimonial{
    background-color: #161c2d;
    height: 525px;
    border: 1px solid transparent;
}
.testimonialContent {
    font-size: 35px;
    font-weight: 700;
    color: #000B86;
    width: 100%; 
    margin-top: 1.25rem;
    text-wrap: wrap;
    text-align: start;
  }
  .testimonialSubContent {
    font-size: 16px;
    font-weight: 500;  
    color: #000B86;
    width: 100%; 
    margin-top: 1.25rem;
    text-wrap: wrap;
  }
  .testimonialMinorContent {
    font-size: 10px;
    font-weight: 700;
    color: #000B86;
    width: 100%; 
    margin-top: 0.2rem;
    text-wrap: wrap;
    text-align: start;
  }

  /* Adjust height of slides for smaller screens */
@media screen and (max-width: 767px) {
    .slide {
        height: 200px;
    }
    /* General styles for mobile responsiveness */
.summitbannerContent {
    font-size: 1.2rem; /* Decrease font size for smaller screens */
}

.slideCard {
    height: auto; /* Let the height adjust based on content */
    width: 90%; /* Adjust width to fit smaller screens */
    margin: 20px auto; /* Center the card */
    border-radius: 10px;
    padding: 10px;
}

.slideshow {
    margin: 0 auto;
    overflow: hidden;
}

.slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
    font-size: 0;
    margin-bottom: 20px;
    margin-top: -20px;
}

.videoSource {
    display: none; /* Hide video source on smaller screens if needed */
}

.video {
    height: auto; /* Let the height adjust based on content */
    background-color: black;
}

.cardTestimonial {
    height: auto; /* Let the height adjust based on content */
    border: 1px solid transparent;
}

.testimonialContent {
    font-size: 24px; /* Decrease font size for smaller screens */
}

.testimonialSubContent {
    font-size: 14px; /* Decrease font size for smaller screens */
}

.testimonialMinorContent {
    font-size: 10px;
}
}

/* .iframe-wrapper {
    height: 700px;
    overflow: hidden;
    background-color: #161c2d;
  }
  
  .iframe-wrapper iframe {
    height: 900px;
    margin-top: -100px;
    margin-bottom: -58%;
  } */

/* Default styles for iframe */
iframe {
  height: calc(100vw/2.77);
  width: 100%;
}
  
  /* Responsive styles for smaller screens */
  @media only screen and (max-width: 768px) {
    iframe {
      height: calc(100vw/2.77);
      width: 100%;
    }
  }
  
  /* Responsive styles for even smaller screens */
  @media only screen and (max-width: 480px) {
    iframe {
      height: 300px; /* Further decrease height for smaller screens */
    }
  }


/* iPhone Styles */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px) 
  and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles for iPhones */
  #mobileView{
    visibility: visible;
  }
  #deskTopView{
   display: none;
  }
}

/* Android Styles */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
  /* Styles for Android phones */
  #mobileView{
    visibility: visible;
  }
  #deskTopView{
   display: none;
  }
}
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
  /* Styles for Android phones */
  #mobileView{
    visibility: visible;
  }
  #deskTopView{
   display: none;
  }
}
/* Styles for screens wider than 768px (typical tablet or desktop screens) */
@media screen and (min-width: 768px) {
    #mobileView{
        display: none!important;
      }
      #deskTopView{
       visibility: visible;
      }
}