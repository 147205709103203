.summitSpeaker {
  padding-top: 102px;
  padding-bottom: 42px;
  background: radial-gradient(50% 50% at 50% 50%, #0012e3 0%, #00046c 100%);
  background-image: url(../../../assets//images//summit/SummerSpeakerBG.png);
  background-repeat: no-repeat;
  background-position: right top;
  background-size: cover;

}
.summitSpeakerBG {
}
.ourSpearkText {
  margin-bottom: 50px;
}
.speakersCards {
  margin: 0;
  padding: 0;
  height: 100%;
}
.speakersCards .img {
  width: 100%;
  height: 480px;
  margin-bottom: 31px;
  /* commented for now when we add speakers need this background: ; */
  background: linear-gradient(180deg, #002ba5 0%, #0043ff 100%);  
}
.speakersCards .img img {
  position: absolute;
  bottom: 0;
  z-index: 5;
  width: 100%;
  /* height: 100%; */
  object-fit: contain;
  object-position: center;
}
.speakersCards h3 {
  margin: 0;
  padding: 0;
  font-size: 30px;
  font-weight: 700;
  line-height: 37px;
  color: #fff;
  margin-bottom: 5px;
}
.speakersCards p {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 20px;
}
.speakersCards .anchReadmore {
  margin: 0;
  padding: 8px 0px 13px 0px;
  width: 145px;
  height: 45px;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  color: #fff;
  border-radius: 4px;
  border: 1px solid #fff;
  margin-bottom: 20px;
}
.speakersCards .anchReadmore:hover {
  background-color: #0014ff;
}
.trangleSpear {
  bottom: 0;
  z-index: 1;
  width: 100%;
}
@media (max-width: 768px) {
  .speakersCards .img{
    /* height: 275px; */
  }
  .ourSpearkText {
    margin-bottom: 100px;
  }
}