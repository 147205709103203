.bannerSectionBlueAbout {
 
}

.bannerSectionBlueAboutBG {
  height: 735.876px;
  background: url(../../../assets/images/about/about-banner.png) no-repeat left top;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bannerSectionBlueAbout .bannerContentAbout {
  margin: 0 auto;
  gap: 40px;
}

.bannerContentAbout .textCourageousAbout {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}

.bannerContentAbout h1 {
  font-family: Roboto;
    font-size: 70px;
    font-weight: 700;
    line-height: 75px;
    letter-spacing: 0em;
    text-align: center;
    gap: 22px;    
}

/* @media screen and (max-width: 767px) {
  .bannerSectionBlue .bannerContent {
    width: 100%;
    padding: 4rem 1.5rem;
  }

  .bannerContent h1 {
    font-size: 2rem;
    line-height: 2rem;
    width: 100%;
  }
} */

/* Media query for mobile */
@media only screen and (max-width: 768px) {
  .bannerSectionBlueAboutBG {
   
  }

  .bannerContentAbout h1 {
    font-size: 40px; /* Decrease font size for better readability on mobile */
    line-height: 45px; /* Adjust line height for better spacing */
  }

  .bannerContentAbout .textCourageousAbout {
    font-size: 14px; /* Decrease font size for better readability on mobile */
    line-height: 18px; /* Adjust line height for better spacing */
  }
}

