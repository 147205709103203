.partnerUkraineSetion {
  background-color: #ffd602;
  padding-top: 120px;
  padding-bottom: 50px;
  height: 594px;
}
.partnerUkraineSetion::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 286px;
  background: #135dac;
  z-index: 1;
}

.cardUkraine {
  margin: 0;
  width: 998px;
  padding: 0px 61px 0px 61px;
  z-index: 2;
}
.hdPartnerUkraine {
  font-size: 64px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -0.25px;
  color: #ffd602;
  padding-bottom: 150px;
}
.praPartnerUkraine {
  font-size: 24px;
  font-weight: 700;
  line-height: 38px;
  color: #135dac;
  /* margin-bottom: 46px;
  margin-top: 20px; */
}
.readMoreBtn {
  margin: 35px 0px 35px 0px;
  padding: 8px 0px 0px 0px;
  width: 180px;
  height: 48px;
  border-radius: 4px;
  background: #fff;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #0a2647;
  align-self: baseline;
}
.readMoreBtn:hover {
  background: #0014ff;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .cardUkraine {
    width: 100%;
  }
  .praPartnerUkraine {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 20px;
  }
  .partnerUkraineSetion {
    height: 100%;
  }
  .readMoreBtn {
    font-size: 18px;
    padding: 10px 0px 0px 0px;
  }
  .readMoreBtn:hover {
    background: #fff;
    color: #0a2647;
  }
}
@media only screen and (max-width: 480px) {
  .hdPartnerUkraine {
    font-size: 40px;
    line-height: 48px;
    padding-bottom: 90px;
    padding: 10px 0px 0px 0px;
  }
  .readMoreBtn:hover {
    background: #fff;
    color: #0a2647;
  }
  .hdPartnerUkraine {
    padding-bottom: 120px 
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .hdPartnerUkraine {
    font-size: 50px;
    line-height: 56px;
  }
  
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .cardUkraine {
    width: 100%;
  }
  .praPartnerUkraine {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 20px;
  }
  .partnerUkraineSetion {
    height: 100%;
    padding-bottom: 20px;
  }
  .readMoreBtn {
    font-size: 18px;
  }
  .hdPartnerUkraine {
    padding-bottom: 120px 
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .cardUkraine {
    width: 100%;
  }
}