
.summitAboutbannerSection23 {
    margin: 0;
    padding: 0;
  }
    .textSummit2023 {
      font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
    }
  .summitAboutbannerSection23BG {
    height: 735.876px;
    background: url(../../../assets/images/about/bgSummit2023.png) no-repeat left top;
    background-size: cover;
  }
  
  .summitAboutbannerSection23 .summit23bannerHeadingsec {
    font-family: Roboto;
    font-size: 70px;
    font-weight: 700;
    line-height: 75px;
    letter-spacing: 0em;
    text-align: center;
    
  }

  
  @media screen and (max-width: 767px) {
    .summitAboutbannerSection23 .summit23bannerHeadingsec  {
     font-size: 3rem;
    line-height: 3rem;
    width: 100%;
    }

  }
  /* iPad Mini and iPad Air */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (-webkit-min-device-pixel-ratio: 1) {

.summitAboutbannerSection23 {
  /* Adjust margin and padding for iPad Mini and iPad Air */
  margin: 0;
  padding: 0;
}

.summitAboutbannerSection23BG {
  /* Adjust height for iPad Mini and iPad Air */

  /* Adjust background image position for better display */
  background-position: center;
}

.summitAboutbannerSection23 .summit23bannerHeadingsec {
  /* Adjust font size for iPad Mini and iPad Air */
  font-size: 40px;
  /* Adjust line height for better readability on iPad Mini and iPad Air */
  line-height: 36px;
  /* Adjust margin-top for spacing */
  margin-top: 10px;
}

}

/* Media query targeting Mac OS devices */
@media screen and (min-device-width: 0px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  /* Your specific styles for Mac devices go here */
  /* For example */
  .summitAboutbannerSection23 .summit23bannerHeadingsec {
    /* Adjust font size for iPad Mini and iPad Air */
    font-size: 3rem;
    line-height: 3rem;
    width: 100%;
  }
}