/* Home more info css slider   */
.ProgramDetailsSEction {
  background-color: #141414;
  padding-top: 70px;
  background-image: url(../../../assets/images/ProgramDetailBG.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 50px;
  background-attachment: fixed;
}
.date-show {
  text-transform: none;
}
.programDetailCard .ulProgram.core-program li {
  word-wrap: break-word;
}
.ProgramBlock {
  width: 809px;
}
.ProgramBlock span {
  margin-bottom: 50px;
}
.ProgramBlock p {
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 0px;
  padding-bottom: 10px;
}
.bannerHeadingPD {
  font-size: 48px;
  font-weight: 700;
  line-height: 75px;
  color: #fff;
}

.programTime {
  font-size: 24px;
  font-weight: 500;
  margin-top: 20px;
}
.programTime span {
  margin-right: 22px;
}

.programDetailCard {
  border: 3px solid #fff;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 25.841px 12px 20px 12px;
  height: 100%;
}
.programDetailCard .programTime {
  padding-left: 14px;
  padding-right: 14px;
}
.programDetailCard .programTime .icon {
  width: 24px;
  margin-right: 4px;
  margin-bottom: 4px;
}
.programDetailCard .programTime .text {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 1px;
}

.programDetailCard .hrLine {
  opacity: 10;
  border: 0;
  height: 1px;
  width: 100%;
  background: rgba(255, 255, 255, 0.15);
  margin-bottom: 21px;
}
.programDetailCard .ProgramText {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 1px;
}
.programDetailCard .ulProgram {
  margin: 0px 0px 16px 36px;
  padding: 0;
}
.programDetailCard .ulProgram li {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 1px;
}
.programDetailCard .ulProgram li &::marker {
  font-size: 14px;
}
.programDetailCard .title {
  /* margin: 0px 0px 30px 0px; */
  padding: 0;
  font-size: 24px !important;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 1px;
}
.horizontalLine {
  opacity: 10;
  border: 0;
  height: 1px;
  width: 100%;
  background: rgba(255, 255, 255, 0.15);
  margin-bottom: 15px;
  margin-top: 10px;
}

.galaDinner {
  font-size: 45px;
}

.link-button {
  background-color: transparent;
  border: 1px solid #fff;
  color: white;
  cursor: pointer;
  padding: 0.625rem 2rem 0.563rem 2.125rem;
  flex: 1;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  max-height: 2.813rem;
  white-space: nowrap;
  z-index: 2;
  position: relative;
  font-size: 1.125rem;
  line-height: 1.625rem;
  font-weight: 600;
  font-family: Roboto;
  text-align: left;
}
.link-button:hover {
  background-color: rgba(230, 230, 230, 0.09);
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
}
.link-button.selected {
  background-color: #FFFFFF;
  color: #0a2647;
}

.divaabutton1,
.readmore1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.readmore1 {
  flex: 1;
  padding: 0 0.25rem 0 0;
  gap: 0 1.313rem;
}
.divaabutton1 {
  width: 15.813rem;
  padding: 0 0 1.375rem;
  box-sizing: border-box;
}
.GalaDateDiv{
  width: 60%;
}
@media screen and (max-width: 767px) {
  .ProgramBlock {
    width: 100%;
  }
  .galaDinner {
    font-size: 30px;
  }
  .GalaDateDiv{
    width: 100%;
  }
}


@media screen and (max-width: 479px) {
  .galaDinner {
    font-size: 30px;
  }
  .ProgramDetailsSEction {
    padding-top: 80px;
  }

  .ProgramBlock p {
    font-size: 18px;
    line-height: 24px;
  }

  .bannerHeadingPD {
    font-size: 36px;
    line-height: 55px;
  }

  .programTime {
    font-size: 20px;
    margin-top: 15px;
  }

  .programDetailCard {
    padding: 15.841px 12px 30px 12px;
  }

  .programDetailCard .programTime .text {
    font-size: 16px;
    line-height: 24px;
  }

  .programDetailCard .hrLine {
    margin-bottom: 15px;
  }

  .programDetailCard .ProgramText {
    font-size: 18px;
    line-height: 24px;
  }

  .programDetailCard .ulProgram li {
    font-size: 14px;
    line-height: 20px;
  }

  .programDetailCard .title {
    font-size: 20px !important;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .horizontalLine {
    margin-bottom: 30px;
  }

  .link-button {
    padding: 0.563rem 1.5rem 0.5rem 1.75rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .GalaDateDiv{
    width: 100%;
  }
  .link-button:hover {
    background-color: transparent;
    color: white;
  }
}
