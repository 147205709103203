.enfa-service {
  background: radial-gradient(50% 50% at 50% 50%, #0012e3 0%, #00046c 100%);
  background-image: url(../../../assets/images/summit/SummerSpeakerBG.png);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;
  background-color: #161c2d;
  padding-top: 50px;
  padding-bottom: 50px;
}

.enfa-service .column{
  margin-bottom: 4.5rem ;
}


.servicecard {
  border: 1px solid transparent;
}

.servicecard .serviceCircle {
  width: 192px;
  height: 192px;
  border-radius: 50%;
  border: 3px solid #fff;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  margin: 0px auto;
  z-index: 5;
}

.servicecard .serviceCircle img {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.servicecard .serviceContent {
  z-index: 2;
  margin-top: 100px;
  padding: 111px 40px 75px 40px;
  height: 350px;
  border: 3px solid #fff;
  /* background: rgba(255, 255, 255, 0.2); */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.Services {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  text-transform: uppercase;
}
.ENFAServiceHeading {
  font-size: 3rem;
  font-weight: 700;
  line-height: 4.6875rem;
}
.servicecard .serviceContent h3 {
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px; /* 115% */
}
.servicecard .serviceContent p {
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height:  26.656px ;
}

@media (max-width: 768px) {
  .Services {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }

  .ENFAServiceHeading {
    font-size: 2.5rem;
    line-height: 3.75rem;
  }

  .servicecard .serviceContent h3 {
    font-size: 1rem;
    line-height: 1.875rem;
  }

  .servicecard .serviceContent p {
    width: auto;
    font-size: 0.875rem;
    line-height: 150%;
  }
}
@media (max-width: 480px) {
  .servicecard .serviceContent {
    height: 275px;
  }
  
  .Services {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .ENFAServiceHeading {
    font-size: 2rem;
    line-height: 3rem;
  }

  .servicecard .serviceContent h3 {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .servicecard .serviceContent p {
    width: auto;
    font-size: 0.75rem;
    line-height: 125%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .servicecard .serviceContent p {
    width: auto;
    font-size:15px 25px;
    line-height: 125%;
  }
}