
.popup-overlay-Thankyou {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-Thankyou {
    position: relative;
    padding: 20px;
    width: 500px;
    height: 180px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    background-color: #161c2d;
    border: 1px solid;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 50vh;
    padding: 5px;
    border-radius: 8px;
    color: white;
  }
  .popup-Thankyou-content{
    max-height: 50vh;
    scrollbar-width: thin;
    scrollbar-color: transparent #555;
    text-align: center;
  }
  .popup-Thankyou p {
    margin-bottom: 10px;
  }
  .close-popup {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    z-index: 1;
    align-items: center;
    justify-content: center;
    height: 26px;
    width: 26px;
    padding: 0;
    border: none;
    border-radius: 5px;
    background: none;
    color: #fff;
  }
  
  .close-popup::before {
    content: "×";
    font-size: larger;
  }
  @media only screen and (max-width: 767px) {
    .popup-Thankyou {
      width: 375px;
    }
  }