.registrationFormContainer {
  width: 60rem;
  padding: 5px;
}

@media screen and (max-width: 768x) {
  .container {
    width: 100%;
  }
}

.RegistrationFormSec {
  background-color: #161c2d;
}

.showBackground {
  background-image: url(../../../assets/images/Registration/RegistrationFormBG.png);
}

.RegistrationFormSecBG {
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
  padding-top: 70px;
  padding-bottom: 50px;
}

.RegistrationFormSecBG .registrationText {
  color: #fff;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.3125rem;
  /* 123.333% */
}

.RegistrationFormSecBG .companyText {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 36px
}

.who-is-paying1 {
  color: var(--www-obforum-com-about-us-1440x810-default-nero);
}

.span12 {
  color: var(--unsuccesfull-incorrect);
}

.who-is-paying-container1 {
  height: 0.75rem;
  position: relative;
  font-size: var(--body-text-regular-size);
  font-family: var(--text-style-body-body-l);
  box-sizing: border-box;
  padding-right: var(--padding-xl);
}

.who-is-paying-container1 {
  width: 9.063rem;
  text-align: left;
  display: inline-block;
}

.text9 {
  width: 1.313rem;
  position: relative;
  font-size: var(--validation-text-h1-regular-size);
  font-family: var(--validation-text-h1-regular);
  color: var(--unsuccesfull-incorrect);
  text-align: left;
  display: none;
}

.default-input-field1,
.email-input {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.default-input-field1 {
  gap: 0.313rem 0;
  z-index: 1;
}

.email-input {
  margin: 0;
  padding: 0 var(--padding-12xs) 0 0;
  box-sizing: border-box;
  gap: 1rem 0;
  max-width: 100%;
}

.submitBtn {
  cursor: pointer;
  border: 0;
  padding: var(--padding-mid) var(--padding-base);
  background-color: var(--customer-portal-primary);
  flex: 1;
  border-radius: var(--br-8xs);
  flex-direction: row;
  min-width: 19.46875rem;
  color: var(--www-obforum-com-about-us-1440x810-default-nero);
  height: 58px;
}

.required-asterisk {
  color: var(--unsuccesfull-incorrect);
  margin-left: 0.125rem;
}

.text-danger .required-asterisk {
  color: var(--unsuccesfull-incorrect);
}

.PhoneInputCountry {
  margin: 0px 15px 0px 10px;
}

.PhoneInputInput {
  border: none;
}

.custom-select-wrapper {
  display: grid;
  position: relative;
}

.custom-select-container {
  position: relative;
  display: inline-block;
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 20px;
}

.select-arrow {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  pointer-events: none;
}

.privateBtn,
.companyBtn,
.company1 .private1 {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-decoration: none;
  padding: var(--padding-mid) var(--padding-base);
  border-radius: var(--br-8xs);
  flex: 1;
  cursor: pointer;
  border: 0;
  flex-direction: row;
  text-decoration: none;
  width:100%;
  padding-left: var(--padding-xl);
  color: var(--Customer-Portal-Primary, #2248AC);

}

.private1,
.company1 {
  width: 7rem;
  position: relative;
  font-size: var(--body-text-regular-size);
  font-family: var(--text-style-body-body-l);
  text-align: center;
}

.privateBtn:hover {
  background-color: var(--color-gainsboro-100);
}

.companyBtn:hover {
  background-color: var(--color-gainsboro-100);
}

.privateBtn.active,
.companyBtn.active {
  background-color: var(--customer-portal-primary);
  color: var(--www-obforum-com-about-us-1440x810-default-nero);
}

.privateBtn.active:hover,
.companyBtn.active:hover,
.submitBtn:hover {
  background-color: var(--color-royalblue);
}

.registrationFormContainer .form-label {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.scroll {
  overflow-y: auto;
  overflow-x: hidden;
}

.registrationFormContainer .content {
  height: 500px;
}

.scroll1::-webkit-scrollbar {
  width: 5px;
}

.scroll1::-webkit-scrollbar-track {
  background: #ddd;
}

.scroll1::-webkit-scrollbar-thumb {
  background: #666; 
}

@media only screen and (min-width: 481px) and (max-width: 768px) {

  .privateBtn.active:hover,
  .companyBtn.active:hover,
  .submitBtn:hover {
    background-color: var(--customer-portal-primary);
  }

  .registrationFormContainer {
    width: 100%;
  }
}

@media only screen and  (max-width: 768px) {

  .privateBtn,
  .companyBtn,
  .company1 .private1 {
    width: 100%;
    margin: 5px 0px;
  }

  .registrationFormContainer {
    width: 100%;
  }
  .RegistrationFormSecBG {
    padding-top: 45px;
    padding-bottom: 45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .registrationFormContainer {
    width: 100%;
  }
}