/* Home about us  */
.summit2023AboutSection {
  background-color: #161c2d;
}

.summit2023AboutSectionBG {
  background-image: url(../../../assets/images/Polygon.png);
  background-repeat: no-repeat;
  padding-top: 20px
}

.summit2023EnfaBox {
  margin: 0px;
  padding: 0px;
}

.summit2023EnfaBox .textEnfaSummit23 {
  margin-bottom: 30px;
  margin-bottom: 10px;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 4rem;
  letter-spacing: -0.01563rem;
  text-transform: uppercase;
}

.summit2023EnfaBox .titleEmpoweringSummit23 {
  margin-bottom: 10px;
  margin-top: 64px;
  font-size: 5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 5.125rem;
  letter-spacing: -0.01563rem;
  text-transform: uppercase;
}

.summit2023EnfaBox .EmpoweringBlockSummit23 {
  margin: 0px;
  padding: 0px;
}

.lineVSummit23 {
  width: 78.78px;
  height: 2px;
  background: #f4d000;
  margin-right: 24px;
  flex-shrink: 0;
  margin-top: 6px;
}

.summit2023EnfaBox .EmpoweringBlockSummit23 .praEmpowerDivSummit23 {
  margin: 0px;
  padding: 0px;
}

.praEmpowerDivSummit23 p {
  margin: 0px 0px 27px 0px;
  padding: 0px;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
}

.btnRegisterSummit23 {
  padding: 8px 0px 0px 0px;
  width: 180px;
  height: 48px;
  border-radius: 4px;
  background: #fff;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #0a2647;
  border: 0;
}

.btnRegisterSummit23:hover {
  background: #0014ff;
  color: #fff;
}

/* Default styles for .EmoImgBigSmallSummit23 */
.EmoImgBigSmallSummit23 {
  padding: 40px;
  margin-left: -10%;
}

@media only screen and (max-width: 1024px) {
  .EmoImgBigSmallSummit23 {
    padding: 20px;
    margin-left: -5%;
  }
}


.EmoImgBigSmallSummit23 .bigSummit23 {
  width: 100%;
  position: relative;
}

.EmoImgBigSmallSummit23 .smallSummit23 {
  margin: -100px auto 0px auto;
  width: 480px;
  height: 400px;
  position: relative;
}

@media only screen and (max-width: 1024px) {
  .EmoImgBigSmallSummit23 .smallSummit23 {
    width: 320px;
    height: 267px;
    margin-top: -80px;
  }
}

/* Media query for Android phones */
@media only screen and (min-device-width: 360px) and (max-device-width: 740px){
  .summit2023EnfaBox .textEnfaSummit23 {
    font-size: 2rem; /* Adjust font size for smaller screens */
    line-height: 3rem; /* Adjust line height for smaller screens */
  }
  .summit2023EnfaBox .titleEmpoweringSummit23 {
    font-size: 3rem; /* Adjust font size for smaller screens */
    margin-top: 32px; /* Adjust margin top for smaller screens */
    line-height: 3.5rem; /* Adjust line height for smaller screens */
  }
  .EmoImgBigSmallSummit23 {
    padding: 20px; /* Adjust padding for smaller screens */
    margin-left: 0; /* Center align for smaller screens */
  }

  .EmoImgBigSmallSummit23 .smallSummit23 {
    margin-top: -50px; /* Adjust top margin for smaller screens */
    width: 75%; /* Full width for smaller screens */
    height: auto; /* Auto height for smaller screens */
  }
  .summit2023EnfaBox .EmpoweringBlockSummit23 .praEmpowerDivSummit23 {
    margin: 0px;
    padding: 0px;
    margin-bottom: 20px;
  }
}

/* Media query for iPhones */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) {
  .summit2023EnfaBox .textEnfaSummit23 {
    font-size: 1.5rem; /* Adjust font size for smaller screens */
    line-height: 2rem; /* Adjust line height for smaller screens */
  }
  .summit2023EnfaBox .titleEmpoweringSummit23 {
    font-size: 3rem; /* Adjust font size for smaller screens */
    margin-top: 32px; /* Adjust top margin for smaller screens */
  }
  .EmoImgBigSmallSummit23 {
    padding: 20px; /* Adjust padding for smaller screens */
    margin-left: 0; /* Center align for smaller screens */
  }

  .EmoImgBigSmallSummit23 .smallSummit23 {
    margin-top: -50px; /* Adjust top margin for smaller screens */
    width: 75%; /* Full width for smaller screens */
    height: auto; /* Auto height for smaller screens */
  }
  .summit2023EnfaBox .EmpoweringBlockSummit23 .praEmpowerDivSummit23 {
    margin: 0px;
    padding: 0px;
    margin-bottom: 20px;
  }
}

/* Responsive styles for tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .summit2023EnfaBox .textEnfaSummit23 {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
  
  .summit2023EnfaBox .titleEmpoweringSummit23 {
    font-size: 4rem;
    line-height: 4.125rem;
    margin-top: 40px;
  }
  
  .EmoImgBigSmallSummit23 {
    padding: 0px;
    margin-left: 0%;
  }

  .EmoImgBigSmallSummit23 .smallSummit23 {
    width: 420px;
    height: 267px;
    margin-top: -120px;
    margin-bottom: 10%;
}
.summit2023EnfaBox .EmpoweringBlockSummit23 .praEmpowerDivSummit23 {
  margin: 0px;
  padding: 0px;
  margin-bottom: 20px;
}
}

/* Responsive styles for iPad mini and iPad Air */
@media only screen and (min-width: 1024px) {
  .summit2023EnfaBox .textEnfaSummit23 {
    font-size: 2rem;
    line-height: 3rem;
  }
  
  .summit2023EnfaBox .titleEmpoweringSummit23 {
    font-size: 3rem;
    line-height: 3.125rem;
    margin-top: 32px;
  }

  
  .summit2023EnfaBox .EmpoweringBlockSummit23 .praEmpowerDivSummit23 {
    margin: 0px;
    padding: 0px;
    margin-bottom: 20px;
  }
}


/* Styles for devices with screens smaller than 360 pixels in width */
@media only screen and (max-width: 359px) {
  .summit2023EnfaBox .textEnfaSummit23 {
    font-size: 2rem; /* Adjust font size for smaller screens */
    line-height: 3rem; /* Adjust line height for smaller screens */
  }
  .summit2023EnfaBox .titleEmpoweringSummit23 {
    font-size: 3rem; /* Adjust font size for smaller screens */
    margin-top: 32px; /* Adjust margin top for smaller screens */
    line-height: 3.5rem; /* Adjust line height for smaller screens */
  }
  .EmoImgBigSmallSummit23 {
    padding: 0px; /* Adjust padding for smaller screens */
    margin-left: 0; /* Center align for smaller screens */
  }

  .EmoImgBigSmallSummit23 .smallSummit23 {
    margin-top: -50px; /* Adjust top margin for smaller screens */
    width: 75%; /* Full width for smaller screens */
    height: auto; /* Auto height for smaller screens */
  }
  .summit2023EnfaBox .EmpoweringBlockSummit23 .praEmpowerDivSummit23 {
    margin: 0px;
    padding: 0px;
    margin-bottom: 20px;
  }
}