.thanks-title {
    color: #2248AC;
    font-family: Poppins;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;


}

.desc {
    color: #212B36;
    font-family: Poppins;
    font-size: 21px;
    font-weight: 500;
    line-height: 31.5px;
    text-align: center;
    width: 733.91px;

}

.btnokay {
	margin: 0;
	padding: 7px 44px 5px 44px;
  padding-left: 50px;
  padding-right: 50px;
	border-radius: 4px;
	background: #2248AC;
	font-size: 20px;
	font-weight: 600;
	letter-spacing: 0.5px;
	color: #FFFFFF;
	border: 0;
}

.btnokay:hover {
	background: #0014ff;
	color: #fff;
}

  
  @media only screen and (max-width: 768px) {
    .desc {
        
        width: 400px;
    
    }
  }