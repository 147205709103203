/* Home about us  */
.homeAboutSection {
  /* height: 100vh; */
  background-color: #161c2d;
  padding-bottom: 50px;
}
.homeAboutSectionBG {
  background-image: url(../../../assets/images/Polygon.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.homeEnfaBox {
  margin: 0px;
  padding: 20px 0px;
}

.homeEnfaBox .textEnfa {
  margin-bottom: 30px;
  margin-bottom: 10px;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3rem;
  letter-spacing: -0.01563rem;
  text-transform: uppercase;
}

.homeEnfaBox .titleEmpowering {
  margin-bottom: 10px;
  margin-top: 64px;
  font-size: 4.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 5rem;
  letter-spacing: -0.01563rem;
  text-transform: uppercase;
}

.homeEnfaBox .EmpoweringBlock {
  margin: 0px;
  padding: 0px;
}

.lineV {
  width: 78.78px;
  height: 2px;
  background: #f4d000;
  margin-right: 24px;
  flex-shrink: 0;
  margin-top: 6px;
}

.homeEnfaBox .EmpoweringBlock .praEmpowerDiv {
  margin: 0px;
  padding: 0px;
}

.praEmpowerDiv p {
  margin: 0px 0px 27px 0px;
  padding: 0px;
  font-size: 17px;
  font-weight: 400;
  line-height: 27px;
}

.btnRegisterSummit24 {
  padding: 10px 0px 0px 0px;
  width: 180px;
  height: 48px;
  border-radius: 4px;
  background: #fff;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #0a2647;
  border: 0;
}

.btnRegisterSummit24:hover {
  background: #0014ff;
  color: #fff;
}

.EmoImgBigSmall {
  padding-top: 80px;
}

.EmoImgBigSmall .big {
  width: 100%;
  position: relative;
}
.EmoImgBigSmall .big img{
  width: 100%;
}

.EmoImgBigSmall .small {
  margin: -315px auto 0px auto;
  width: 480px;
  height: 480px;
  position: relative;
}
@media only screen and (max-width: 480px) {
  /* Styles for mobile screens */
  .homeEnfaBox .textEnfa {
    font-size: 14px;
    line-height: 18px;
    margin-top: 35px;
    margin-bottom: 8px;
  }

  .homeEnfaBox .titleEmpowering {
    font-size: 40px;
    line-height: 36px;
    margin-bottom: 15px;
  }

  .lineV {
    display: none;
  }

  .praEmpowerDiv p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 15px;
  }

  .btnRegisterSummit24 {
    margin: auto;
    font-size: 16px;
    padding: 12px 0px 0px 0px;
  }
  .btnRegisterSummit24:hover {
    background: #fff;
    color: #0a2647;
  }
  .EmoImgBigSmall .small {
    margin: -155px auto 0px auto;
    width: 240px;
    height: 240px;
  }
  .homeEnfaBox {
    text-align: center;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {

  /* Styles for tablet screens */
  .homeEnfaBox .textEnfa {
    font-size: 20px;
    line-height: 18px;
    margin-top: 35px;
    margin-bottom: 8px;
  }

  .homeEnfaBox .titleEmpowering {
    margin-bottom: 20px;
  }

  .lineV {
    display: none;
  }

  .praEmpowerDiv p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 18px;
  }

  .btnRegisterSummit24 {
    margin: auto;
    font-size: 18px;
    padding: 12px 0px 0px 0px;
  }

  .EmoImgBigSmall .small {
    margin: -180px auto 0px auto;
    width: 360px;
    height: 360px;
  }
}
