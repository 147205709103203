.bannerSectionBlue {
  padding-top: 0;
  height: 941px;
}

.bannerSectionBlueBG {
  /* background-image: url(../../../assets/images/banner-trangle.svg);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 550px; */
    /* we add this after client confirmation */
    background: linear-gradient(180deg, rgba(22, 29, 63, 0.00) -33.01%, #000439bd 87.47%);
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
}

.bannerSectionBlue .bannerContent {
  margin: 0 auto;
  padding: 9.375rem 0 9.375rem 0;
  max-width: 46.25rem;
  text-align: center;
}

.bannerContent .textCourageous {
  font-size: 1rem;
  font-weight: 500;
  line-height: 0.25rem;
  margin-bottom: 0.875rem;
  /* text-shadow: 10px 0px 16px #000; */;
}

.bannerContent h1 {
  font-size: 4.375rem;
  font-weight: 700;
  line-height: 4.6875rem;
  color: #fff;
  margin-bottom: 1.25rem;
  width: 85%;
  /* text-shadow: 10px 0px 16px #000; */;
}

.bannerContent .date {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.875rem;
  margin-bottom: 3.75rem;
}

.bannerSectionP {
  height: 57px;
  gap: 1.75rem;
  /* text-shadow: 10px 0px 16px #000; */;
}

@media screen and (max-width: 767px) {
  .bannerSectionBlue .bannerContent {
    width: 100%;
    padding: 4rem 1.5rem;
  }

  .bannerContent h1 {
    font-size: 3rem;
    line-height: 3.125rem;
    width: 100%;
  }
  .bannerSectionP{
    height: 30%;
  }
}
.bannerSectionBlue {
  position: relative;
}

#bgVideo video{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  object-fit: cover;
}

.bannerSectionBlueBG {
  position: relative; 
  z-index: 2; 
}


.bannerContent {
  color: #fff;
}
@media screen and (min-width: 1024px) {
  .bannerSectionP {
   width: 888px;
  }
}