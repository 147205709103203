/* footer */
.footerSection {
  background: #1b1b1b;
  padding: 79px 10px 24.825px 15px;
  color: #fff;
}

.footlogo {
  padding-right: 1rem;
}

.footlogo a {
  display: inline-block;
  margin-bottom: 12px;
}

.footlogo p {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  width: 251.906px;
}

.footLinks {}

.footLinks span {
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 27px;
}

.footLinks .NewLetterText {
  margin-bottom: 20px;
}

.footAddres {
  margin: 0px 0px 30px 0px;
  padding: 0;
}

.footAddres p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
}

.footAddres .icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin-right: 16px;
}

.footAddres a {
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  color: #fff;
  margin-bottom: 30px;
}

.footAddres a:hover {
  color: #010998;
}

.newleeterForm {
  margin: 0px 0px 24px 0px;
  padding: 0;
}

.newleeterForm .inpNewLetter {
  margin: 0px 16px 0px 0px;
  padding: 16px;
  width: 20.5rem;
  height: 56px;
  border-radius: 14px;
  border: 1px solid #76aeff;
  background: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.newleeterForm .inpNewLetter::placeholder {
  color: #a2a9b0;
}

.newleeterForm .btnSubscribe {
  margin: 0;
  padding: 0;
  width: 160px;
  height: 56px;
  border-radius: 8px;
  background: #3a86ff;
  border: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #fff;
}

.newleeterForm .btnSubscribe:hover {
  background: #010998;
}

.footSocial a {
  margin: 0px 16px 0px 0px;
  padding: 0;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 40px;
  background: #3a86ff;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 40px;
}

.footSocial a:hover {
  background: #010998;
}

.copyDiv {
  padding-top: 25px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.5px;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .newleeterForm .inpNewLetter {
    margin: 0px 16px 16px 0px;
    width: 100%;
  }

  .newleeterForm .btnSubscribe {
    width: 100%;
  }

  .footSocial {
    justify-content: center;
  }

  .footSocial a {
    margin: 0px 8px 0px 8px;
  }
  .footAddres a:hover {
    color: #fff;
  }
  .newleeterForm .btnSubscribe:hover {
    background: #3a86ff;
    color: #fff;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .newleeterForm .inpNewLetter {
    margin: 0px 16px 16px 0px;
    width: 100%;
  }
  .footlogo p {
    width: 100%;
  }
}
@media only screen and (min-width: 820px) and (max-width: 1180px) {
  .newleeterForm .inpNewLetter {
    margin: 0px 16px 16px 0px;
    width: 100%;
  }

  .footlogo p {
    width: 100%;
  }
  .footerSection {
    padding: 79px 50px 24.825px 20px;
  }
}

@media (min-width: 1200px){
  .footerSection {
    padding-left: 35px;
  }
}


/* Media query for screens with a minimum width of 1440px (extra-large desktops) */
@media only screen and (min-width: 1440px) {
  .footerSection {
    padding-left: 50px;
  }
}

/* Media query for screens with a minimum width of 1600px (ultra-wide screens) */
@media only screen and (min-width: 1600px) {
  .footerSection {
    padding-left: 60px;
  }
}