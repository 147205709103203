.homeENFASectionSummit2023 {
  background: radial-gradient(50% 50% at 50% 50%, #0014ff 0%, #0007b9 100%);
}
.homeENFASectionSummit2023 .leftSide {
  display: flex;
  max-width: 50%;
  flex-shrink: 0;
}
.homeENFASectionSummit2023 .rightSide {
  max-width: 40%;
  flex-shrink: 0;
  padding-top: 40px;
  margin-left: 8%;
}
.homeENFASectionSummit2023 .rightSide span.osla-business {
  display: block;
  font-size: 30px;
  font-weight: 700;
  line-height: 37px;
  margin-bottom: 37px;
  max-width: 420px;
}
.homeENFASectionSummit2023 .rightSide p {
  margin: 0;
  padding: 0;
  display: block;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  margin-bottom: 27px;
  max-width: 560px;
}
.btnRegister {
  margin: 40px 0px 40px 0px;
  padding: 10px 0px 0px 20px;
  width: 50%;
  height: 48px;
  border-radius: 4px;
  background: #fff;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #0a2647;
  align-self: baseline;
  display: flex;
  justify-content: left;
}
.btnRegister:hover {
  background: #0014ff;
  color: #fff;
}
.imgLeft{
  width: 706px;
  margin-left: 10%;
  opacity: 0.6px;
  padding: 5%
}


/* Media queries for responsive design */
@media only screen and (max-width: 1024px) {
  .homeENFASectionSummit2023 .leftSide,
  .homeENFASectionSummit2023 .rightSide {
    flex: 1 1 100%;
    max-width: 100%;
    text-wrap: balance;
  }

  .homeENFASectionSummit2023 .rightSide {
    padding-top: 30px;
  }

  .imgLeft {
    max-width: 100%;
    height: 100%;
    margin-left: 0%;
  }
}

/* Styles for tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .homeENFASectionSummit2023 {
    flex-direction: column;
  }

  .homeENFASectionSummit2023 .leftSide,
  .homeENFASectionSummit2023 .rightSide {
    flex: 1 1 100%;
    max-width: 100%;
    margin-left: 0;
  }

  .homeENFASectionSummit2023 .rightSide {
    padding-top: 5px;
    margin: 30px;
  }

  .btnRegister {
    max-width: 100%;
    width: 50%;
    padding: 10px;
    font-size: 16px;
    margin: 20px 0;
  }
	.imgLeft{
    width: 100%;
    height: auto;
  
  }
  .homeENFASectionSummit2023 .rightSide span.osla-business {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 24px;
    max-width: 100%;
  }

  .homeENFASectionSummit2023 .rightSide p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
    max-width: 100%;
  }
}


/* Styles for iPhones */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) {
  .homeENFASectionSummit2023 {
    flex-direction: column;
  }

  .homeENFASectionSummit2023 .leftSide,
  .homeENFASectionSummit2023 .rightSide {
    flex: 1 1 100%;
    max-width: 100%;
    margin-left: 0;
  }

  .homeENFASectionSummit2023 .rightSide {
    padding-top: 10px;
    margin: 10px;
  }

  .btnRegister {
    max-width: 100%;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    margin: 20px 0;
  }
  .imgLeft{
    width: 100%;
    max-width: 706px;
    height: auto;
  
  }
  .homeENFASectionSummit2023 .rightSide span.osla-business {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 24px;
    max-width: 100%;
  }

  .homeENFASectionSummit2023 .rightSide p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
    max-width: 100%;
  }
}


/* Styles for Android phones */
@media only screen and (min-device-width: 360px) and (max-device-width: 740px) {
  .homeENFASectionSummit2023 {
    flex-direction: column;
  }

  .homeENFASectionSummit2023 .leftSide,
  .homeENFASectionSummit2023 .rightSide {
    flex: 1 1 100%;
    max-width: 100%;
    margin-left: 0;
  }

  .homeENFASectionSummit2023 .rightSide {
    padding-top: 5px;
    margin: 30px;
  }

  .btnRegister {
    max-width: 100%;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    margin: 20px 0;
  }
  .imgLeft{
    width: 100%;
    max-width: 706px;
    height: auto;
  
  }
  .homeENFASectionSummit2023 .rightSide span.osla-business {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 24px;
    max-width: 100%;
  }

  .homeENFASectionSummit2023 .rightSide p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
    max-width: 100%;
  }
}


/* Styles for devices with screens smaller than 360 pixels in width */
@media only screen and (max-width: 359px) {
  .homeENFASectionSummit2023 {
    flex-direction: column;
  }

  .homeENFASectionSummit2023 .leftSide,
  .homeENFASectionSummit2023 .rightSide {
    flex: 1 1 100%;
    max-width: 100%;
    margin-left: 0;
  }

  .homeENFASectionSummit2023 .rightSide {
    padding-top: 10px;
    margin: 30px;
  }

  .btnRegister {
    max-width: 100%;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    margin: 20px 0;
  }
  .imgLeft{
    width: 100%;
    max-width: 706px;
    height: auto;
  
  }
  .homeENFASectionSummit2023 .rightSide span.osla-business {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 24px;
    max-width: 100%;
  }

  .homeENFASectionSummit2023 .rightSide p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
    max-width: 100%;
  }
}

/* For Resolution Above 120% */
@media only screen and (min-resolution: 121dpi) and (max-resolution: 200dpi) {
	.homeENFASectionSummit2023 .leftSide,
  .homeENFASectionSummit2023 .rightSide {
    flex: 1 1 100%;
    max-width: 100%;
    text-wrap: balance;
  }

  .homeENFASectionSummit2023 .rightSide {
    padding-top: 30px;
  }

  .imgLeft {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .btnRegister{
    width: 50%;
  }
}

.wrapper { 
  border : 2px solid #000; 
  overflow:hidden;
}
