.MembershipFormContainer {
  width: 60rem;
  padding: 5px;
}

@media screen and (max-width: 767px) {
  .container {
    width: 100%;
  }
}

.MembershipFormSec {
  background-color: #161c2d;
}

.showBackground {
  background-image: url(../../../assets/images/Registration/RegistrationFormBG.png);
  ;
}
.MembershipFormSecBG {
  background-color: #161c2d;
  padding-top: 100px;
  background-position: 0px;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 30px;
  padding-bottom: 30px;
}

.MembershipFormSecBG .registrationText {
  color: #fff;
  font-family: Roboto;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.3125rem;
  /* 123.333% */
}

.text9 {
  width: 1.313rem;
  position: relative;
  font-size: var(--validation-text-h1-regular-size);
  font-family: var(--validation-text-h1-regular);
  color: var(--unsuccesfull-incorrect);
  text-align: left;
  display: none;
}

.default-input-field1,
.email-input {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.default-input-field1 {
  gap: 0.313rem 0;
  z-index: 1;
}

.email-input {
  margin: 0;
  padding: 0 var(--padding-12xs) 0 0;
  box-sizing: border-box;
  gap: 1rem 0;
  max-width: 100%;
}

.submitBtn {
  cursor: pointer;
  border: 0;
  padding: var(--padding-mid) var(--padding-base);
  background-color: var(--customer-portal-primary);
  flex: 1;
  border-radius: var(--br-8xs);
  flex-direction: row;
  min-width: 19.46875rem;
  color: var(--www-obforum-com-about-us-1440x810-default-nero);
  height: 58px;
}

.required-asterisk {
  color: var(--unsuccesfull-incorrect);
  margin-left: 0.125rem;
}

.text-danger .required-asterisk {
  color: var(--unsuccesfull-incorrect);
}

.PhoneInputCountry {
  margin: 0px 15px 0px 10px;
}

.PhoneInputInput {
  border: none;
}

.custom-select-wrapper {
  display: grid;
  position: relative;
}

.custom-select-container {
  position: relative;
  display: inline-block;
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 20px;
}

.select-arrow {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  pointer-events: none;
}
.MembershipFormSec .form-label {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.scroll {
  overflow-y: auto;
  overflow-x: hidden;
}

.contentMembership {
  height: 500px;
}

.scroll1::-webkit-scrollbar {
  width: 5px;
}

.scroll1::-webkit-scrollbar-track {
  background: #ddd;
}

.scroll1::-webkit-scrollbar-thumb {
  background: #666;
}

@media only screen and (max-width: 767px) {
  .MembershipFormContainer {
    width: 100%;
  }
}

/* Media query for screens between 481px and 1024px */
@media only screen and (min-width: 481px) and (max-width: 1024px) {
  .MembershipFormContainer {
    width: 100%;
  }
}

/* Media query for screens between 768px and 1024px */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .MembershipFormContainer {
    width: 100%;
  }
}