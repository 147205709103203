.bgColor {
  background: radial-gradient(#0014FF, #0007B9);
  margin-bottom: -20px;
}

@media screen and (max-width: 767px) {
  .bgColor {
    margin-bottom: -20px;
  }
}

.summithomeAboutSection {
  padding-bottom: 53px;
  /* height: 100vh; */
  background-color: #161D3F;
}

.summithomeCounter {
  padding: 64px 0px 20px 0px;
}

.summithomeCounter .summitcounter {
  margin: 0px 33px;
  padding: 20px;
}

.summithomeCounter .summitcounter b {
  margin: 0;
  padding: 0;
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
}

.summithomeCounter .summitcounter p {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
}



