.headerWrap .logo {
	width: 72.75px;
	height: 63.5px;
}

.logoEnfa{
	position: absolute;
	top: 50%;
	left: 5%;
	transform: translate(-50%, -50%);
}
.MenuWrap {
	margin: 0;
	padding: 0;
}

.MenuWrap ul {
	margin: 0;
	padding: 0;
}

.MenuWrap ul li {
	margin: 0;
	padding: 0;
}

.navbar-expand-lg {
	margin: 0;
	padding: 0;
}

.dashboard-selector {
	margin: 0;
	padding: 7px 23px 7px 23px;
	color: #fff !important;
	font-size: 15px;
	font-weight: 600;
	line-height: 28px;
	background: #0014ff;
	color: #f9f9f9;
}

.active-selector {
	margin: 0;
	padding: 7px 23px 7px 23px;
	color: #fff !important;
	font-size: 18px;
	font-weight: 600;
	line-height: 28px;
}

.navbar-expand-lg .navbar-nav .nav-link {
	margin: 0;
	padding: 7px 23px 7px 23px;
	color: #fff !important;
	font-size: 18px;
	white-space: nowrap;
	font-weight: 600;
	line-height: 28px;
	border-radius: 5px;
}

.navbar-expand-lg .navbar-nav .nav-link:hover,
.navbar-expand-lg .navbar-nav .nav-link.active {
	border-radius: 5px;
}

.btnLogin {
	margin: 0;
	padding: 7px 44px 5px 44px;
	border-radius: 4px;
	background: #fff;
	font-size: 20px;
	font-weight: 600;
	letter-spacing: 0.5px;
	color: #0a2647;
	border: 0;
}

.btnLogin:hover {
	background: #0014ff;
	color: #fff;
}

.fadeWhite {
	background-color:#161c2dfc;
	transition: 1s;
}

.menuBuuton {
	position: absolute;
	right: 0;
	top: 0;
	color: white !important;
}

.menuItem {
	position: absolute;
	top: 40px;
	text-align: end;
	right: 0px;
}

.navItem {
	font-family: Roboto;
	font-size: 18px;
	font-weight: 600;
	line-height: 28px;
	letter-spacing: 0em;
	text-align: center;
	color: #FFFFFF !important;

}

@media (min-width : 992px) {
	.offcanvas {
		visibility: visible;
		position: relative;
		background: none;
		border: none;
		justify-content: end;
		color: red;
	}
}

@media (max-width : 992px) {
	.offcanvas {
		width: 250px !important;
	}

	.offcanvas-start-lg {
		top: 0;
		left: 0;
		border-right: 1px solid rgba(0, 0, 0, .2);
		transform: translateX(-100%);
	}
}



.boxShadow {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.logoImage {
	height: 63px;
	width: 100%;
}

/* Styles for desktop screens */

/* For Resolution Above 120% */
@media only screen and (min-resolution: 121dpi) and (max-resolution: 200dpi) {
	/* Your styles for desktop devices with resolution more than 120% and up to 500% */
	.navItem {
		font-family: Roboto;
		font-size: 12px;
		font-weight: 600;
		line-height: 28px;
		letter-spacing: 0em;
		text-align: center;
		color: #FFFFFF !important;

	}
}


  @media screen and (min-width: 1024px) and (min-height: 508px) {
	/* Your styles here */
	.logoEnfa{
		position: absolute;
		top: 50%;
		left: 7%;
		transform: translate(-50%, -50%);
	}
  }
@media only screen and (min-width: 1025px) {
	/* Your desktop-specific styles here */
	.header {
		margin: 0px;
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 101;
		height: 100px;
		padding: 0px 50px;
	}
	#navMobile {
		display: none !important;
	}
}

@media only screen and (min-width: 1024px) {
	/* Your desktop-specific styles here */
	.header {
		margin: 0px;
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 101;
		height: 100px;
		padding: 0px 50px;
	}
	#navMobile {
		display: none !important;
	}
	
}

/* Styles for tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {

	/* Your tablet-specific styles here */
	.header {
		margin: 0px;
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 101;
		height: 85px;
		padding: 0px 10px;
	}

	#deskMobile {
		display: none !important;
	}

	.navbar-expand-lg .navbar-nav .nav-link:hover,
	.navbar-expand-lg .navbar-nav .nav-link.active {
		background: none;
	}
	.navbar-expand-lg .navbar-nav .nav-link {
		margin: 0;
		padding: 10px 0px 10px 0px;
		color: #fff !important;
		font-size: 18px;
		font-weight: 600;
		line-height: 28px;
	}
	.logoImage {
		height: 40px;
		width: 100%;
	}

	.logoEnfa{
		position: absolute;
		top: 50%;
		left: 7%;
		transform: translate(-50%, -50%);
	}
	
}


/* Styles for Mac computers */
@media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 2) {

	/* Your Mac-specific styles here */
	.header {
		margin: 0px;
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 101;
		height: 100px;
		padding: 0px 50px;
	}

	#navMobile {
		display: none !important;
	}
}


/* Styles for iPhones */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) {

	/* Your iPhone-specific styles here */
	.header {
		margin: 0px;
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 101;
		height: 85px;
		padding: 0px 10px;
	}

	#deskMobile {
		display: none !important;
	}

	.navbar-expand-lg .navbar-nav .nav-link:hover,
	.navbar-expand-lg .navbar-nav .nav-link.active {
		background: none;
	}
	.navbar-expand-lg .navbar-nav .nav-link {
		margin: 0;
		padding: 10px 0px 10px 0px;
		color: #fff !important;
		font-size: 18px;
		font-weight: 600;
		line-height: 28px;
	}
	.logoImage {
		height: 40px;
		width: 100%;
	}
	.logoEnfa{
		position: absolute;
		top: 50%;
		left: 12%;
		transform: translate(-50%, -50%);
	}
	.btnLogin:hover {
		background-color: #fff;
		color: #0a2647;
	  }
}


/* Styles for Android phones */
@media only screen and (min-device-width: 360px) and (max-device-width: 740px) {

	/* Your Android phone-specific styles here */
	.header {
		margin: 0px;
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 101;
		height: 85px;
		padding: 0px 10px;
	}

	#deskMobile {
		display: none !important;
	}

	.navbar-expand-lg .navbar-nav .nav-link:hover,
	.navbar-expand-lg .navbar-nav .nav-link.active {
		background: none;
	}
	.navbar-expand-lg .navbar-nav .nav-link {
		margin: 0;
		padding: 10px 0px 10px 0px;
		color: #fff !important;
		font-size: 18px;
		font-weight: 600;
		line-height: 28px;
	}
	.logoImage {
		height: 40px;
		width: 100%;
	}
	.logoEnfa{
		position: absolute;
		top: 50%;
		left: 12%;
		transform: translate(-50%, -50%);
	}
	.btnLogin:hover {
		background-color: #fff;
		color: #0a2647;
	  }
}


/* Styles for devices with screens smaller than 360 pixels in width */
@media only screen and (max-width: 359px) {

	/* Your styles for smaller devices here */
	.header {
		margin: 0px;
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 101;
		height: 85px;
		padding: 0px 10px;
	}

	#deskMobile {
		display: none !important;
	}

	.navbar-expand-lg .navbar-nav .nav-link:hover,
	.navbar-expand-lg .navbar-nav .nav-link.active {
		background: none;
	}
	.navbar-expand-lg .navbar-nav .nav-link {
		margin: 0;
		padding: 10px 0px 10px 0px;
		color: #fff !important;
		font-size: 18px;
		font-weight: 600;
		line-height: 28px;
	}
	.logoImage {
		height: 40px;
		width: 100%;
	}
	.logoEnfa{
		position: absolute;
		top: 50%;
		left: 15%;
		transform: translate(-50%, -50%);
	}
	.btnLogin:hover {
		background-color: #fff;
		color: #0a2647;
	  }
}
.ml-15{
	margin-left: 15px;
}