
.partnerbannerText {
	max-width: 100%;
	height: auto;
	background: url(../../../assets/images/about/bgPartners.png) no-repeat left top;
	background-size: cover;
	background-repeat: no-repeat;
  
  }
    .aboutMissionPartner {
      margin: 0;
      padding:46px;
    }

  .aboutMissionPartner .textOur {
    font-size: 48px;
    /* Use viewport width to set font size responsively */
    font-weight: 700;
    line-height: 0.5;
    letter-spacing: -0.25px;
    margin-top: 5vw;
  }
  
  .aboutMissionPartner .textMisson {
    font-size: 96px;
    /* Use viewport width to set font size responsively */
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: -0.25px;
    text-align: center;
  }
  
  
  .aboutMissionPartner p {
    font-size: 17px;
    /* Use viewport width to set font size responsively */
    font-weight: 400;
    letter-spacing: 0.5px;
    padding-bottom: 24px;
   
  }

  
  .textPartnerToBe{
    font-size: 30px;
    font-weight: 500;
    font-family: Roboto;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  
  /* Default styles for .btnPartner */
  .btnPartner {
  	padding: 8px 0px 0px 0px;
  	width: 40%;
  	height: 48px;
  	border-radius: 4px;
  	background: #fff;
  	font-size: 20px;
  	font-weight: 600;
  	letter-spacing: 0.5px;
  	color: #0a2647;
  	border: 0;
    margin-bottom: 5%;
  }

  .btnPartner:hover {
    background: #0014ff;
    color: #fff;
  }

  .parent {
	display: flex;
	flex-wrap: wrap;
	margin: 5px;
  
  }
  
  .child {
	flex: 1 0 21%; 
	margin: 5px;
  }

/* Responsive styles for mobile and iPhone */
@media only screen and (max-width: 767px) {
  .aboutMissionPartner {
    padding: 5vw; /* Adjusted padding for smaller screens */
  }

  .aboutMissionPartner .textOur {
    font-size: 24px; /* Adjusted font size for smaller screens */
    margin-top: 10vw; /* Adjusted margin for smaller screens */
    line-height: 1;
  }

  .aboutMissionPartner .textMisson {
    font-size: 48px; /* Adjusted font size for smaller screens */
    line-height: 1;
  }

  .aboutMissionPartner p {
    font-size: 17px; /* Adjusted font size for smaller screens */
    line-height: 1;
  }

  .textPartnerToBe {
    font-size: 17px; /* Adjusted font size for smaller screens */
    margin-top: 10px; /* Adjusted margin for smaller screens */
    margin-bottom: 10px; /* Adjusted margin for smaller screens */
  }
  .parent {
    flex-direction: column; /* Stack children vertically on smaller screens */
  }
  
  .child {
    flex: 1 0 100%; /* Full width for each child on smaller screens */
    margin: 5px 0; /* Adjusted margin for smaller screens */
  }
  .btnPartner {
    width: 100%; /* Full width for the button on smaller screens */
    font-size: 16px; /* Adjusted font size for smaller screens */
    margin-bottom: 3%; /* Adjusted margin for smaller screens */
    font-size: 17px;
    padding: 8px 0px 8px 0px;
    height: 100%;
  }
  .btnPartner:hover {
    background-color: #fff;
    color: #0a2647;
  }
}


/* Responsive styles for tablets */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .aboutMissionPartner {
    padding: 4vw; /* Adjusted padding for tablets */
  }

  .aboutMissionPartner .textOur {
    font-size: 26px; /* Adjusted font size for tablets */
    margin-top: 3.5vw; /* Adjusted margin for tablets */
  }

  .aboutMissionPartner .textMisson {
    font-size: 48px; /* Adjusted font size for tablets */
  }

  .aboutMissionPartner p {
    font-size: 17px; /* Adjusted font size for tablets */
    padding-bottom: 1vw; /* Adjusted padding for tablets */
  }

  .textPartnerToBe {
    font-size: 2vw; /* Adjusted font size for tablets */
    margin-top: 1vw; /* Adjusted margin for tablets */
    margin-bottom: 1vw; /* Adjusted margin for tablets */
  }
  .btnPartner {
    width: 50%; /* Full width for the button on smaller screens */
    font-size: 16px; /* Adjusted font size for smaller screens */
    margin-bottom: 3%; /* Adjusted margin for smaller screens */
    font-size: 17px;
    padding: 8px 0px 8px 0px;
    height: 100%;
  }
  .btnPartner:hover {
    background-color: #fff;
    color: #0a2647;
  }
}

/* ONLY FOR RESOLUTION MEDIA QUERY */
@media only screen and (min-resolution: 121dpi) and (max-resolution: 200dpi) {
	.aboutMissionPartner {
    padding: 4vw; /* Adjusted padding for tablets */
  }

  .aboutMissionPartner .textOur {
    font-size: 26px; /* Adjusted font size for tablets */
    margin-top: 3.5vw; /* Adjusted margin for tablets */
  }

  .aboutMissionPartner .textMisson {
    font-size: 48px; /* Adjusted font size for tablets */
  }

  .aboutMissionPartner p {
    font-size: 17px; /* Adjusted font size for tablets */
    padding-bottom: 1vw; /* Adjusted padding for tablets */
  }

  .textPartnerToBe {
    font-size: 2vw; /* Adjusted font size for tablets */
    margin-top: 1vw; /* Adjusted margin for tablets */
    margin-bottom: 1vw; /* Adjusted margin for tablets */
  }
  .btnPartner {
    width: 50%; /* Full width for the button on smaller screens */
    font-size: 16px; /* Adjusted font size for smaller screens */
    margin-bottom: 3%; /* Adjusted margin for smaller screens */
    font-size: 17px;
    padding: 8px 0px 8px 0px;
    height: 100%;
  }
}