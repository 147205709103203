.summary {
    margin-top: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 10px;
    text-align: left;
  }
 
  h3 {
  
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  .rowBorder{
    padding-top: 0.5em;
    border-top: 2px solid #b7bbbf;
    border-bottom: 2px solid #b7bbbf;

  }