/* Styles for the yellow section */
.summit2023UkraineSetionYellow {
  background-color: #ffd602;
  padding-top: 20px;
  padding-bottom: 20px;
}

/* Styles for the blue section */
.summit2023UkraineSetionBlue {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 28%;
  background: #135dac;
  z-index: 1;
}

/* Styles for the blue card */
.cardUkraineSummit2023Blue {
  margin: 0;
  max-width: 998px;
  padding-top: 10%;
  z-index: 2;
}

/* Styles for the yellow card */
.cardUkraineSummit2023Yellow {
  margin: 0;
  max-width: 998px;
  z-index: 2;
}

/* Header style */
.hdsummit2023Ukraine {
  font-size: 23px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: -0.25px;
  color: #ffd602;
  margin-top: -100px;
  padding-bottom: 40px;
}

/* Paragraph style */
.prasummit2023Ukraine {
  font-size: 22px;
  font-weight: 700;
  line-height: 38px;
  color: #135dac;
  margin-top: 20px;
}




/* Media queries for responsive design */


/* Media queries for responsive design */
@media only screen and (max-width: 1024px) {
  .summit2023UkraineSetionBlue,
  .summit2023UkraineSetionYellow {
    height: 20%; /* Adjust the height as needed */
    max-width: 100%;
  }
  .summit2023UkraineSetionBlue {
    height: 20%;
  }
  .hdsummit2023Ukraine {
    font-size: 18px;
    line-height: 28px;
    margin-top: -70px;
    padding-bottom: 40px;
  }
  .prasummit2023Ukraine {
    font-size: 16px;
    line-height: 28px;
  }
}

/* Styles for tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .summit2023UkraineSetionBlue,
  .summit2023UkraineSetionYellow {
    height: 20%; /* Adjust the height as needed */
  }
  .summit2023UkraineSetionBlue {
    height: 20%;
  }
  .hdsummit2023Ukraine {
    font-size: 18px;
    line-height: 28px;
    margin-top: -50px;
    padding-bottom: 40px;
  }
  .prasummit2023Ukraine {
    font-size: 16px;
    line-height: 28px;
  }
}


/* Styles for iPhones */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) {
  .summit2023UkraineSetionYellow {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .summit2023UkraineSetionBlue {
    height: 20%;
  }
  .cardUkraineSummit2023Blue,
  .cardUkraineSummit2023Yellow {
    max-width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  .hdsummit2023Ukraine {
    font-size: 18px;
    line-height: 30px;
    padding-bottom: 50px;
    margin-top: 20px;
  }
  .prasummit2023Ukraine {
    font-size: 16px;
    line-height: 28px;
  }
}


/* Styles for Android phones */
@media only screen and (min-device-width: 360px) and (max-device-width: 740px) {
  .summit2023UkraineSetionYellow {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .summit2023UkraineSetionBlue {
    height: 20%;
  }
  .cardUkraineSummit2023Blue,
  .cardUkraineSummit2023Yellow {
    max-width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  .hdsummit2023Ukraine {
    font-size: 18px;
    line-height: 30px;
    padding-bottom: 50px;
    margin-top: 20px;
  }
  .prasummit2023Ukraine {
    font-size: 16px;
    line-height: 28px;
  }
}


/* Styles for devices with screens smaller than 360 pixels in width */
@media only screen and (max-width: 359px) {
  .summit2023UkraineSetionYellow {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .summit2023UkraineSetionBlue {
    height: 20%;
  }
  .cardUkraineSummit2023Blue,
  .cardUkraineSummit2023Yellow {
    max-width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  .hdsummit2023Ukraine {
    font-size: 18px;
    line-height: 30px;
    padding-bottom: 50px;
    margin-top: 20px;
  }
  .prasummit2023Ukraine {
    font-size: 16px;
    line-height: 28px;
  }
}

/* For Resolution Above 120% */
@media only screen and (min-resolution: 121dpi) and (max-resolution: 200dpi) {
	
}
