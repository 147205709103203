* {
    box-sizing: border-box;
}
/* Summit - 2023 */
.summit2023About {
    background-color: #161c2d;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
.flex-container {
    resize: horizontal;
    /* Allow horizontal resizing */
    overflow: hidden;
    /* Hide overflow content */
    display: flex;
    margin: 1em;
}

.item {
    margin: 1em;
    padding: 0.5em;
    min-width: 0;
    /* Ensure items can shrink */
}

/* Responsive CSS */
@media screen and (max-width: 768px) {
    .item {
        font-size: 16px;
        /* Adjust font size for smaller screens */
        line-height: 24px;
        /* Adjust line height accordingly */
    }
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .flex-container {
        flex-direction: column;
        /* Change flex direction to column on smaller screens */
    }
}

@media (max-width: 576px) {
    .item {
        font-size: 11px;
        font-size: 16px;
        /* Adjust font size for smaller screens */
        line-height: 24px;
        /* Adjust line height accordingly */
        /* Adjust font size for smaller screens */
    }
}

.initial {
    flex: initial;
}

.auto {
    flex: auto;
}

.none {
    flex: none;
}

.four {
    flex: 4;
}

.two {
    flex: 1;
}

.one {
    flex: 0.5;
}

.recapImg {
    width: 90%;
    /* Default width */
    display: flex;
    justify-content: center;
    align-items: center;
}
.paraTag{
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {

    /* Your CSS styles for iPad Air in landscape mode */
    .flex-container {
        flex-direction: column;
        /* Change flex direction to column on smaller screens */
    }

    .paraTag {
        font-size: 11px;
        font-size: 16px;
        /* Adjust font size for smaller screens */
        line-height: 24px;
        /* Adjust line height accordingly */
        /* Adjust font size for smaller screens */
    }

    .recapImg {
        width: 100%;
        /* Adjust width for smaller screens */
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {

    /* Your CSS styles for iPad Air in portrait mode */
    .flex-container {
        flex-direction: column;
        /* Change flex direction to column on smaller screens */
    }

    .paraTag {
        font-size: 11px;
        font-size: 16px;
        /* Adjust font size for smaller screens */
        line-height: 24px;
        /* Adjust line height accordingly */
        /* Adjust font size for smaller screens */
    }

    .recapImg {
        width: 100%;
        /* Adjust width for even smaller screens */
    }
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .recapImg {
        width: 100%;
        /* Adjust width for smaller screens */
    }
}

@media (max-width: 576px) {
    .recapImg {
        width: 100%;
        /* Adjust width for even smaller screens */
    }
}
.headingTitleUkraine {
    font-size: 48px;
    font-weight: 700;
    line-height: 64px;
    color: #fff;
    text-transform: uppercase;
  }
