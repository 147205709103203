.membershipBannerSection {
    margin: 0;
    padding: 0;
  }
  
  .membershipBannerSectionBG {
    height: 735.876px;
    /* background: url( ../../../assets/images/about/bgSummit2023.png) no-repeat
      center top;
    background-size: cover; */
    background: linear-gradient(180deg, rgba(22, 29, 63, 0.00) -33.01%, #000439bd 87.47%);
  }
  
.membershipBannerSectionBG .bannerHeadingMembership {
    font-size: 4.375rem;
    font-weight: 700;
    line-height: 4.6875rem;
    color: #fff;
    width: 43.45044rem;
    margin-top: 1.25rem;
    /* text-shadow: 10px 0px 16px #000; */;
  }
  @media (max-width: 768px) {
  .membershipBannerSectionBG .bannerHeadingMembership {
      font-size: 3.5rem;
      line-height: 4rem;
      width: auto;
    }
  }
  
  @media (max-width: 480px) {
  .membershipBannerSectionBG .bannerHeadingMembership {
      font-size: 2.5rem;
      line-height: 3rem;
      width: auto;
      margin-top: 0.625rem;
    }
  }
  .MembershipbannerSection {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
    /* text-shadow: 10px 0px 16px #000; */;
  }
  #bgMembershipVideo video{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    object-fit: cover;
  }
  .membershipBannerSection {
    position: relative;
  }
  
  .membershipBannerSectionBG {
    position: relative; 
    z-index: 2; 
  }
  
  
  .membershipBannerSection {
    color: #fff;
  }
  