.css-1qrj99o-control {
    min-height: 58px !important; /* Set the desired height */
  }
  /* Set background color for options after the search div option */
.custom-select-wrapper .css-1pahdxg-control {
    background-color: white;
    color: black;
  }
  
  .custom-select-wrapper .css-1pahdxg-control .css-1uccc91-singleValue {
    background-color: white;
    color: black;
  }
  
  .custom-select-wrapper .css-1pahdxg-control .css-1uccc91-singleValue:not(:first-child) {
    background-color: #f0f0f0; /* Set your desired background color */
  }
  
  /* Set black text color for selected option */
  .custom-select-wrapper .css-1wa3eu0-placeholder,
  .custom-select-wrapper .css-1wy0on6,
  .custom-select-wrapper .css-1o62kds {
    color: black;
  }
  