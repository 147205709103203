.aboutMission {
  /* height: 927.351px; */
  background: url(../../../assets/images/about/about-mission.png) no-repeat left top;
  background-size: cover;
  padding-top: 10%;
  /* Adjust padding to maintain aspect ratio on smaller screens */
  padding-bottom: 10%;
  /* Adjust padding to maintain aspect ratio on smaller screens */
}

.aboutMissionCnt {
  margin: 0;
  padding: 0;
  max-width: 622.46px;
}

.aboutMissionCnt .textOur {
  font-size: 48px;
  /* Use viewport width to set font size responsively */
  font-weight: 700;
  line-height: 0.5;
  letter-spacing: -0.25px;
  margin-top: 5vw;
}

.aboutMissionCnt .textMisson {
  font-size: 96px;
  /* Use viewport width to set font size responsively */
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: -0.25px;
}


.aboutMissionCnt p {
  font-size: 17px;
  /* Use viewport width to set font size responsively */
  font-weight: 400;
  letter-spacing: 0.5px;
  padding-bottom: 24px;
 
}


/* Base styles */
.missionPhotoList {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.missionPhotoList .img {
  margin: 0 1vw 1vw 0; /* Adjust margin for responsive spacing */
  padding: 0;
  width: 30%; /* Adjust width for responsive layout */
  max-width: 200px; /* Set maximum width to prevent items from getting too large on large screens */
  height: auto; /* Allow height to adjust based on width */
}

.missionPhotoList .img img {
  width: 100%;
  height: auto;
}

.summitimg {
  width: 100%;
  transition: all 2s ease-in-out;
  display: block;
  margin: 5px;
}

.summitimg:hover {
  transform: scale(1.2);
}

.menu-container {
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pt-150{
  padding-top: 150px
}
.pb-150{
  padding-bottom: 150px;
}
.pl-20{
  padding-left: 20px;
}
.pr-20{
  padding-right: 20px;
}


/* Responsive styles for iPhone and mobile devices */
@media only screen and (max-width: 767px) {
  .aboutMission {
    padding-top: 2%;
    padding-bottom: 2%;
  }
  
  .aboutMissionCnt {
    width: 100%; /* Full width on smaller screens */
  }

  .aboutMissionCnt .textOur {
    font-size: 24px; /* Adjusted font size for smaller screens */
  }

  .aboutMissionCnt .textMisson {
    font-size:48px; /* Adjusted font size for smaller screens */
  }

  .aboutMissionCnt p {
    font-size: 17px;/* Adjusted font size for smaller screens */
  }
  .pt-150,
  .pb-150 {
    padding-top: 50px; /* Adjusted padding for smaller screens */
    padding-bottom: 50px; /* Adjusted padding for smaller screens */
  }

  .pl-20,
  .pr-20 {
    padding-left: 10px; /* Adjusted padding for smaller screens */
    padding-right: 10px; /* Adjusted padding for smaller screens */
  }
  .menu-container {
    margin: 0;
    padding: 0;
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .summitimg {
    width: 95%;
    transition: all 2s ease-in-out;
    display: block;
    margin: 5px;
  }
}



/* Responsive styles for tablets and iPads */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .aboutMission {
    padding-top: 2%;
    padding-bottom: 2%;
  }

  .aboutMissionCnt {
    width: 100%; /* Adjusted width for tablets */
  }

  .aboutMissionCnt .textOur {
    font-size: 36px; /* Adjusted font size for tablets */
    margin-top: 40px; /* Adjusted margin for tablets */
  }

  .aboutMissionCnt .textMisson {
    font-size: 72px; /* Adjusted font size for tablets */
  }

  .aboutMissionCnt p {
    font-size: 13px; /* Adjusted font size for tablets */
  }
  .pt-150,
  .pb-150 {
    padding-top: 50px; /* Adjusted padding for smaller screens */
    padding-bottom: 50px; /* Adjusted padding for smaller screens */
  }

  .pl-20,
  .pr-20 {
    padding-left: 10px; /* Adjusted padding for smaller screens */
    padding-right: 10px; /* Adjusted padding for smaller screens */
  }
  .missionPhotoList {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: 15%;
  }
  .menu-container {
    margin: 0;
    padding: 5px;
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}