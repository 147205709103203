.overviewContent {
    background-color: #161c2d;
    padding: 50px 0 20px 0;
}
.overviewContent h3.overviewtitles {
    font-size: 20px;
    color: #fff;
    display: block;
    width: fit-content;
}
.overviewContent table a:hover {
    background: #0014ff;
}
.overviewContent table {
    font-size: 14px;
    margin-bottom: 5px;
    color: #fff;
    border:1px solid #F4D000;
}
.overviewContent table strong {
    font-size: 15px;
}
.overviewContent table a {
    border: 1px solid #0014ff;
    color: #fff;
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    padding: 2px 20px;
    border-radius: 5px;
    line-height: 16px;
}
td.first-td {
    width: 85%;
    padding: .5rem .5rem;
}
span.border-red {
    border: 2px solid #3a86ff;
    width: 100px;
    display: block;
    margin-bottom: 10px;
}

@media screen and (max-width: 900px) {
    td.first-td {
        width: 80%;
        padding: .5rem .5rem;
        font-size: 14px;
    }
}

@media screen and (max-width: 767px) {
    td.first-td {
        width: 68%;
        padding: .5rem .5rem;
        font-size: 14px;
    }
}
@media screen and (max-width: 480px) {
    td.first-td {
        width: 64%;
        padding: .5rem .5rem;
        font-size: 13px;
    }
    .overviewContent table strong {
        font-size: 14px;
    }
    .overviewContent table a {
        padding: 2px 12px;
    }
}
 