.form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
}

.SignupPage {
  color: black;
  height: 100vh !important;
}

.SignupPage .form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
}

.SignupBtn {
  cursor: pointer;
  border: 0;
  padding: 17px ;
  border-radius: 0.3125rem;
  background: var(--Customer-Portal-Primary, #2248ac);
  width: 45%;
  color: white;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  white-space: nowrap;
}

/* .SignupBtn {
  cursor: pointer;
  border: 0;
  padding: 17px;
  border-radius: 0.3125rem;
  background: var(--Customer-Portal-Primary, #2248ac);
  width: 19.46875rem;
  color: white;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
} */
.form-control {
  border-radius: 5px;
  border: 1px solid #b5b5b6;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  height: 58px;
}

.InputGroup {
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid #b5b5b5;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 15px 20px 0px;
  height: 58px;
  /* gap: 20px; */
}

.InputField {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 14px;
  color: var(--Customer-Portal-Secondary, #1a1848);
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* opacity: 0.15; */
}

.PasswordViewImg {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
}

.capitalLetter,
.characters,
.number,
.specialCharacter {
  color: #2248ac;
}

.passwordMustBeContainer {
  height: 26px;
  position: relative;
  font-size: 12px;
  font-weight: 600;
  font-family: Poppins;
  text-align: left;
  flex-shrink: 0;
  max-width: 100%;
  color: #b5b5b5;
}

.passwordMustBeContainerErr {
  height: 26px;
  position: relative;
  font-size: 12px;
  font-weight: 600;
  font-family: Poppins;
  text-align: left;
  flex-shrink: 0;
  max-width: 100%;
  color: #dc3545;
}

.alreadyAct,
.signup {
  align-self: stretch;
  position: relative;
  color: var(--Black-100, #1c1d22);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.signup {
  font-weight: 600;
  color: var(--Customer-Portal-Primary, #2248ac);
}

.noAccountText {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 4px;
}

.noAccountTextWrapper {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
}

.noAccountTextWrapper {
  align-self: stretch;
  height: 10px;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  font-size: var(--paragraph-text-semi-bold-size);
}

.welcomeBack {
  height: 15px;
  font-size: 21px;
  color: var(--Neutral-Dark, #b5b5b5);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.signupMainDiv {
  background-image: url(../../assets/images/login/LeftContent.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.signupRightDiv {
  /* margin-top: 64px;
  margin-bottom: 64px;
  margin-left: 80px;
  margin-right: 80px; */

}

.hello-again {
  color: var(--Dark-Color, #1c1d22);
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.signupLable {
  color: var(--Customer-Portal-Secondary, #1a1848);
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: 767px) {
  .signupMainDiv {
    height: 50%;
    margin-bottom: 20px;
  }

  .signupRightDiv {
    margin: auto;
  }

  .confirmpassword-container {
    margin-top: 30px;
  }
  .SignupBtn {
    width: 100%;
  }
}

@media screen and (min-width: 769px) {

  .signupRightDiv {
    margin: auto;
    padding-left: 5rem;
    padding-right: 5rem;
    /* padding-top: 15px; */

  }
}

/* @media only screen and (min-device-width: 768px) and (max-width: 1366px) 
{
  .signupRightDiv {
    margin: auto;
    padding-left: 6rem;
    padding-right: 6rem;

  }
  .signupMainDiv {
    background-image: url(../../assets/images/login/LeftContent.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 300px;
  }
} */

@media screen and (device-width: 768px) and (device-height: 1024px) {
  .signupRightDiv {
    margin: auto;
    padding-left: 7rem;
    padding-right: 7rem;

  }

  .signupMainDiv {
    background-image: url(../../assets/images/login/LeftContent.svg);

    height: 330px;
  }

}

@media screen and (device-width: 820px) and (device-height: 1180px) {
  .signupRightDiv {
    margin: auto;
    padding-left: 7rem;
    padding-right: 7rem;

  }

  .signupMainDiv {
    background-image: url(../../assets/images/login/LeftContent.svg);

    height: 430px;
  }

}

@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
    .signupRightDiv {
      margin: auto;
      /* padding-left: 7rem;
      padding-right: 7rem; */
    }
  
    .signupMainDiv {
      background-image: url(../../assets/images/login/LeftContent.svg);
      
      height: 500px;
    }
  
    .cust-col {
      width: 100%;
      /* Adjust the width as needed */
    }
}