.ENFAVideoSection{
    position: relative;
    height: 50.74669rem 
}
#bgVideo {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    object-fit: cover;
  }